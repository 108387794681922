import React from "react";
import { withRouter } from "react-router-dom";
import {
  accommodation,
  donation,
  orange_arrow,
  SD_accomadation,
  sd_acc_white,
  SD_annadanam,
  SD_arrow_right_orange,
  SD_divider,
  sd_e_hundi_icon,
  sd_hands,
  SD_parokshaseva,
  SD_Right_Arrow_Blue,
  SD_Right_Arrow_white,
  seva_and_darshanam,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateTwo = ({ history }) => {
  //-------------------------------------------------------------states--------------------------------------------------------//

  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  //-------------------------------------------------------------states--------------------------------------------------------//

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  //-----------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <>
      {" "}
      <div className="top" style={{ backgroundColor: "#f9ebb2" }}>
        <h1>Devotee Services</h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <div className="sd-template-two">
        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={seva_and_darshanam}
              alt="SD_parokshaseva"
              className="sd-logo-img sd-size-four"
            />
          </div>
          <div className="sd-box-one-content">
            <h2>Seva and Darshanam</h2>
            <p>
              Darshanam is the auspicious sight of a deity that has immense
              spiritual significance. The auspicious sight of the deity clears
              the mind...
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva`
                  );
                }
              }}
            >
              Book Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>

        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={SD_annadanam}
              alt="SD_annadanam"
              className="sd-logo-img sd-size-three"
            />
          </div>
          <div className="sd-box-one-content">
            <h2>Annadanam</h2>
            <p>
              The devotees anywhere from the world can serve the people who are
              in hunger by donating to Annadanam Trust..
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/donations`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                  );
                }
              }}
            >
              Donate Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>

        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={accommodation}
              alt="accomadation"
              className="sd-logo-img sd-size-one"
            />
          </div>
          <div className="sd-box-one-content">
            <h2>Accommodation</h2>
            <p>
              Comfortable and hygienic AC/Non AC Rooms, Suite Rooms, Hotels with
              all amenities and near to temple to accommodate pilgrims...
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/accommodations`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/accommodations`
                  );
                }
              }}
            >
              Book Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>

        <div className="sd-box-one ">
          <div className="sd-box-one-image">
            <img
              src={donation}
              alt="Accomadation"
              className="sd-logo-img sd-size-one"
            />
          </div>
          <div className="sd-box-one-content">
            <h2> Donation </h2>
            <p>
              e-Hundi allows donations from people across the globe for the
              welfare of the sacred Sri Kananka Durga. Devotees...
            </p>

            <button
              className="sd-b-orange"
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/donations?to=e-hundi`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations?to=e-hundi`
                  );
                }
              }}
            >
              Donate Now <img src={orange_arrow} alt=">" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(TemplateTwo);
