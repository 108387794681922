import React from "react";
import {
  SD_divider,
  SD_title_divider,
  SD_highlets_border,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-ganapathihomam.scss";
import Sd_loading from "../../../sd-common-components/sd-loading";
const BodyData = {
  ganapathihomam: {
    firstbanner: [
      "Vaagee Shaadyaaha Sumanasaha Sarvardhanamupakrame Yam Nathvaa Krutakrutyaasuhu tam namami gajananam",
      "Vighnesha Vidhimarthanda Chandrendropendravanditha Namo Ganapthe Thubhyam Brahmanaam Brahmanaspathe",
    ],
    firstHeading: ["Suraadhyakshaya", "Namaha"],
    firstDesc: `The above said valuable lines explain the significance of Lord
      Ganapathi. The Akshara Shastra suggests that the letter 'Ga' is the
      most powerful. It is also known through Aagama Sastra that
      'Ganeshaha Tarpanapriyaaha'. The lord Ganapathi according to Mantra
      Sastra can be worshipped in 32 ways and 56 avatars. A complete
      prayer is that when Lord Ganapathi is recalled in 440 mantras.
      Devotees who idolise any lord must worship Lord Ganapathi first.
      Success is rewarded when those who don't worship any lord, worship
      Lord Ganapathi. Success is not rewarded to those who worship other
      gods but don't offer prayers to Lord Ganapathi. This is why, Lord
      Ganapathi earned the names, 'First of all' and 'The most important
      one'. Hence, called by the name Suradhyaakshaaya.`,
    secondbanner: [
      <>
        Tham Ganapathim Yo Vismarathi Loke <br />
        Santhathamu Bhagyo Majjathi Sasoke
      </>,
    ],
    secondHeading:
      "The above Lines say that troubles are invited if we forgot Lord Ganapathi.",
    secondDesc: ` Srisaila Kshetram is the centre of the whole world and the abode of
      great teachings. In such an auspicious location, in the presence of
      Sakshi Ganapathi, the one who worshipped and considered his parents
      the world, if Ganapathi Homam is offered, all hurdles shall be
      destroyed. It would also bestow the blessings of Sri Mallikarjuna Swamy and 
      Bhramaramba and Chintamani Ganapathi, who is a culmination of all
      Ganapathi mantras.`,
  },
  varalakshmivratham: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <>
        <br />
        <br />
        Goddess Lakshmi, one of the manifestations of Shakthi, the cosmic
        energy, recited in eight ways as Asta Lakshmis. The eight energies,
        those balance the world to run in a way of well being are love,
        knowledge, strength, wealth, fame, pleasure, peace and earth. Asta
        Lakshmis preserve these eight energies and radiates forces for the
        preserver of Universe, Lord Vishnu. As people always strive for
        prosperous life with health and wealth, these eight energies are base
        for the rhythmic play of mankind. People believe worshipping Goddess
        Lakshmi will reward them a life of fulfilment with health and wealth.
        Particularly in Hindu Mythology, a Friday of Sravana Masam is chosen to
        worship Goddess Lakshmi as this day pleases her the most. Goddess
        Varalakshmi bleesses the Varams (boons) for her devotees as her name
        itself represents the union of Asta Lakshmis and their Boons. Srisailam
        Devasthanam offers holy service to Goddess Lakshmi on the day of
        Varalakshmi Vratham, where devotees can participate indirectly in that
        holy service via Paroksha Seva.
      </>
    ),
    secondbanner: (
      <>
        Lakshmi Ksheerasamudra rajatanayam sriranga dhameswareem
        <br />
        Dasibootha samastha deva vanitham lokaika deepamkuram
        <br />
        Sri manmanda kataksha labdhavibhavat brahmendra gangadharam
        <br />
        Twaam thrailokyakutumbhineem sarasijaam vande mukunda priyam
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        Goddess Lakshmi pleases with the above slokam, while recited during the
        Vratham offered on any Friday of Sravana Masam. As we all knew that a
        woman can only sooth and align the heart for other woman, most of Hindu
        women offer this Vratham to please Goddess Lakshmi to invite goodness
        for their husbands, children and the whole family. Devotees can now
        avail the service of indirect participation in Varalakshmi Vratham at
        Srisaila Maha Kshetram by booking ticket on the respective dates of Seva
        and invoke the blessings of Goddess Lakshmi, along with Sri Bhramaramba
        sametha Mallikarjuna Swamy
      </>
    ),
  },
  chandihomam: {
    firstbanner: [
      "Durge Smrutha Harasi Bhitimaseshajantoha Swastaihi Smrutamatimativa Shubhaam Dadaasi",
      "Daaridriya Dhukha Bhayahaarini Kaatvadhanyaa Sarvopakaarakaranaya Dayaadhra Chitthaa",
    ],
    firstHeading: null,
    firstDesc:
      "O Devi, every soul who recollects you when they face hurdles shall be liberated of their fears. When those who are untroubled and healthy recall you, you gift them godly knowledge. Similarly, you eradicate the fears and misfortune of your devotees. Who else has got the golden heart to help all the living of the world other than you?",
    secondbanner: [
      <>
        Yaa Devi Sarvabhooteshu Maatrurupena Samstitha
        <br /> Namasthathyai Namasthathyai Namasthathyai Namo Namaha
      </>,
    ],
    secondHeading: null,
    secondDesc: (
      <>
        This shloka details the kindness of the universal mother over her
        devotees and preachers. The devotees shall gain abundant Vaagmayamu when
        prayed to Goddess Sridevi. There are many ways to traditionally pray
        her. Her preachers pray her with many emotions and many names like
        Durga-Chandi-Kaali-Baala-Lalitha-Rajarajeswari-Bagala-Chinnamastha and
        earn her blessings. <br />
        Sri Devi Mahathyamu is a supreme holy book for worshipping the goddess
        with Sri Durga-Chandi Namams. This holy book is also known as Durga
        Saptashathi and Chandi Saptashathi. This holy scripture admires the
        goddess in the form of Maha Kali-Maha Lakshmi-Maha Saraswathi and also
        the Sapthamatrika avatar. <br />
        <br />
        From the Vedas, devotees are bestowed with the knowledge of Karmakaanda
        and Upaasanakanda. To take a part of Puranas and perform Japam, Homam
        and Paraayanam and considering it as an equivalent to Vedas is a rare
        scenario. But, from Markandeya Puranam, from chapters 78 to 90, history
        of the goddess and shlokas have been given most prominence and the glory
        of the goddess has been pronounced equivalent to the Vedas. Also, the
        shlokas have been promoted as powerful mantras.
        <br />
        <br />
        Along with the 578 shlokas in the Durga Sapthashathi, Uvaachalu has been
        promoted as mantras after which, 700 shlokas have been derived. In Durga
        Sapthashathi, the avatars of Maha Kali-Maha Lakshmi-Maha Saraswathi has
        been gloriously described to complete the history of the goddess. The
        Homam is as dear to the goddess as the Durga Sapthashathi.
        <br />
        Also, the last Shakti addressed in Durga Sapthashathi is of Bhramari,
        who is none other than Bharamaraambika Devi.
        <br />
        'Bhraamareethi Cha Maam Lokaasthadha Sthoshyanthi Sarvataha.' This
        shloka clearly states that all Shaktis originated from Bhramari Shakti.
        Those who worship through Durga Sapthashathi Paarayanam and offer Homam
        shall reach their destinations and the world too comes to their feet.
        <br />
        <br />
        Srisaila Khandam clearly states that Maha Kali-Maha Lakshmi-Maha
        Saraswathi avatars described in Devimahatyam originated as one Shakti,
        i.e, Sri Bhramarambika Devi.
        <br />
        Hence, when the most significant Chandi Homam, which is equivalent to
        the Vedas is performed in the presence of universal guardians, Sri
        Mallikarjuna Bhramaramba at the Sri Chakram, i.e, Srisailam, the
        devotees are liberated from worldly troubles and earn the complete
        blessings of Sri Mallikarjuna Swamy and Bhramarambika Devi. `
      </>
    ),
  },
  leelakalyanotsavam: {
    firstbanner: [
      "Pashyanthi Paripullapadmanayana Poornendubhimbaanana Pathyuha Premasamaakula Kulavadhoornatyotsavam Leelayaa",
      "Brahmemdraadi Suropalaalitha Gunam Bhakteshtadaanonmukam Yutkinchitkutukam Manassakalayaddhevi Shubhaayasthu Naha",
    ],
    firstHeading: null,
    firstDesc: (
      <>
        With eyes as large as lotus petals, the face that shines like the moon,
        the qualities lauded by the gods itself, may Bhramarambhika Devi with
        the love from the god Mallikarjuna Swamy shower all their blessings
        together upon us!
        <br />
        60th chapter of Srisaila Khandam describes the marital union of Lord
        Shiva and Goddess Parvathi. Lord Shiva means the provider of
        auspiciousness. Parvathi means the queen of the hills. Lord Shiva and
        Parvathi Devi complete each other to become a part of one another. The
        secret to such a powerful union occurs when the soul that is twined with
        pride, heart, intellect, etc reaches the higher stage of awakening. This
        kind of union is a mystic power. Lord Shiva and Goddess Parvathi
        followed austerity for each other to gain the mystic powers.
        <br />
        Their union is of the highest nature, beyond the gratification in
        physical form. The couple expresses their joy through dance thus
        presenting happiness as a boon to the devotees. Just how language is
        required to express the world, Goddess Parvathi is quintessential for
        the essence of Lord Shiva. Just as there is no body without a soul,
        there is no Lord Shiva without Goddess Parvathi. This is why Lord Shiva
        is addressed as 'Lord Who Is Half Woman' which signifies "totality that
        lies beyond duality". This is why Kalidasu said: 'Vagarthavivi
        Sampruktau...Jagatah Pitarau Vande Parvathiparameshwarau'
      </>
    ),
    secondbanner: <>Kotijanmaakrutaihi Punyeihi Shive Bhakthihi Prajaayathe.</>,
    secondHeading: `Devotees shall earn the good karma
    worth many lives at the feet of Lord Shiva. Lord Shiva and Goddess Parvathi are inseparable,
    that's why.`,
    secondDesc: (
      <>
        'Kamesha baddha maangalya sutra shobhita kandhara...' The nuptial chain
        of goddess Parvathi is very powerful. Shiva preachers in
        Soundaryalahari..'Shivah Shaktya yukto yadi bhavati Shaktah
        prabhavitum.' 'Tava Janani Taatamkamahima'.
        <br />
        'Tvaya Hrutvaa Vaamam Vapuhu Aparitruptena manasaa.' 'Jaganmaatha
        Swaadhinavallabha' 'Suvaasinarchanapreetha'
        'Nijabhrarthumukaambhojachintanaaye Namo Namaha'. These lines describe
        Lord Shiva and Goddess Parvathi as a couple. The goddesses auspicious
        nuptial thread is described as: <br />
        "Mringedivaadu Vibhundani Mringedidiyu Garalamaniyu Mailani Prajakun
        Mringamune Sarvamangala Mangalasutrambunenta Madi Namminado.. In many
        such examples and also through games like Shiva-Sharvaani,
        Bhava-Bhavaani, Rudra-Rudraani, Mruda-Mrudaani, Eesha-Eeshwari, their
        marriage is signified. <br />
        With such mystic powers, Lord Shiva and Goddess Parvathi leave from
        Kailasa to earthy Kailasa, Srisailam as Sri Mallikarjuna Swamy and
        Bhramaramba to shower their blessings upon the world and stand as a
        shining example of a perfect marital couple. So offering Sri
        Mallikarjuna Swamy and Bhramaramba Leelakalyanam is equivalent to
        offering Kalyanam to the mother and father of this world. This Kalyana
        gifts marital bliss, fortune, prosperity. When devotees see the marital
        rituals and hear the mantras, they earn good karma and complete
        blessings from Sri Bhramaramba and Mallikarjuna Swamy. Kalyanam offered
        at Srisailam is unique as the Kalyanam is offered to the lord and
        goddess in their purest forms as Lord Shiva and Goddess Parvathi during
        Sankranti and to their powerful avatars, i.e, Sri Bhramaramba and
        Mallikarjuna Swamy during Maha Shivaratri. This kind of ritual is
        nowhere to be found except at Srisaialam. So devotees can now offer Sri
        Mallikarjuna Swamy and Bhramaramba Leelakalyanam through Paroksha Seva
        organised by Durga Malleswara Swamy Varla Devasthanam.
      </>
    ),
  },
  mrityunjayahomam: {
    firstbanner: [
      "Hasthaambhojayugastha Kumbayugalaadudrutya Toyam Shiraha Shimchintam Karayooryugena Dadatham Svanke Sa Kumbhow Karov",
      "Akshasrak Mrugahasthamanjubhujagatam Moordhastha Chandrasravak Priyoosharthatanam Bhajesha Girijam Treyksham Cha Mrityunjayam.",
    ],
    firstHeading: null,
    firstDesc: (
      <p>
        One of the avatars of Lord Shiva, i.e, of Mrityunjaya is clearly stated
        in the above shloka. Lord Mrityunjaya appears with his third eye and
        eight shoulders. The upper left and right arms of his hold a pitcher of
        Amrutham that is directed to be poured onto himself. One of his right
        hands holds a string of beads and one of his left hand is posed to show
        seal of deer(Mrugi mudra). The remaining hands hold pitchers of amrutham
        <br />
        Lord shiva appears in Mrityunjaya avatar to rescue Markandeya, the son
        of Mrikandu Maharshi from the crutches of Yama. He not only does save
        Markandeya from untimely death but also pronounces him immortal. That's
        why...`
      </p>
    ),
    secondbanner: (
      <>
        Chandrasekaramasraye Mama Kimkarishyathi Vai Yamaha
        <br /> Chandrasekhara Chandrasekhara Chandrasekhara Paahimaam <br />
        Chandrasekhara Chandrasekhara Chandrasekhara Rakshamaam
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <p>
        Markandeya has sung the above lines. Mrityunjaya has three eyes that of
        the sun, fire and moon. The Fire eye destroys the sins, Sun eye
        eradicates ignorance and the moon eye provides relief from soaring
        temperatures. Mrityunjaya Archana and Homam rescues the devotees from
        untimely death and defects in stars. When performed with
        Rudradhyaayanuvaakam and Moolamantra havanam, devotees shall gain the
        results of Rudra Homam and complete blessings of Lord Shiva.
        <br />
        Mrityunjaya Homam when performed at the residence of devotees has its
        benefits but when performed in Srisailam, which is considered as
        incarnation of Kailasa, the centre of the world and in the presence of
        Sri Mallikarjuna Bhramaramba, renders the complete positive outcomes
        that of a Shiva Archana. This Homam not only protects devotees from
        untimely death but also showers the blessings of Sri Bhramarambika and
        Mallikarjuna Swamy
      </p>
    ),
  },
  rudraabhishekam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: null,
    secondbanner: (
      <>
        Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye
        <br />
        Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha
        <br />
        Maayayaa Mohithaatmano Na Janatni Maheshwaram
        <br />
        Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha
        <br />
        Shivaligje Vasatnveya Taani Sarvani
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        The supreme god manifested himself as the Lingam. All the worlds in the
        universe lie within the Lingam. This lingam holds the power of all three
        supreme gods. This is why performing Archana to the lingam is greater
        than performing Archana to the idol. Lingarchana is performing Archana
        to the whole world. All the holy places and all of the universe is
        within the Lingam.
        <br />
        Umamaheshwara himself in Srisaila Khandam stated that the Mahalingam,
        Jyotirlangam, Mallikarjuna Swamy Lingam has all the significance that's
        mentioned above.
      </>
    ),
    thirdBanner: (
      <>
        Shatashaakaghatham Saakshaat, Shatarudriyamutthamam
        <br />
        Tasmaathajjapamaathrena, Sarvapapaihi Pramuchyate
        <br />
        <br />
        Abhishekapriyo Shivaha
        <br />
        <br />
        Vrukshasya Moola sekeena shaakaaha Pushyanti Vai Yadha
        <br />
        Shive Rudre Japaathpreethe Pritha Evasya Devataaha.
      </>
    ),
    thirdDesc: (
      <>
        Just how the branches of a tree strengthen when you water the roots,
        with Rudyaadyaayajapam, Patanam and Abhishekam, Lord shiva and all gods
        associated with him shall be pleased. Hence Rudraadhyaya Abhishekam
        rewards outstanding results.
        <br />
        Na Rudro Rudramarchayeth
        <br />
        (One who isn't a Rudra can not offer Archana to Lord Rudra)
        <br />
        Sri Rudra is the mightiest god. He is regionally called by many names
        like Shivudu, Shankarudu, Mrududu, Pashupati, Bhavudu, etc. Lord Rudra
        is the kindest, wise and omnipresent. His glory is beautifully described
        in many Vedas and Puranas. Atti Rudrunistotramu has got great
        significance in Rudradhyaayam.
        <br />
        Krishna Yajurveda Taitariyasamhitha, Chaturthaakaandam, Panchama
        Prapaatakam Namakamu, Saptamaprapaatakam Chamakamu. The full glory of
        the god is integrated in Rudraadhyaama Namaka-Chamakaadam. Also
        Chamakams compliments as teachings of peace for Namakams. So,
        Namaka-Chamakams are integral part of abhishekams, japams, homams and
        paaraayanams.
        <br />
        <br />
        Firstly, Rudhyaadhyayamu
        <br />
        1.Pradhavaanuvaakam is the prayer to Lord Rudhra
        <br />
        2.From Dvitiyaanuvaakam to Navamaanuvaakam, Lord Rudra is addressed as
        the one with the world and omnipresent mighty lord. In Ashtamaanuvaakam
        Namah Shivaaya, the Shiva Panchaakshari mantram originates.
        <br />
        3.Dashamaanuvaakam is Lord Rudras prayer.
        <br />
        4.Ekaadasaanuvaakam is the prayer to Lord Rudra and his allies.
        <br />
        <br />
        Rudraadhyayam in Yajurvavedam, which is the heart of all four Vedas. has
        been pronounced as Karma, Gyaana and Upaasana Kanda in the Vedas.
        <br />
        <br />
        <br />
        Rudraadhyayam captures the glory of Lord Rudra and his significance.
        Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
        Rudhraabhishekam at the centre of the world, the Sri Chakra, abode to
        the Vedas, Crown place of all mantras, which is Durga Malleswara Swamy Varla Devasthanam,
        inhibited by Sri Mallikarjuna Bhramaramba liberates devotees from all
        hurdles, renders the wisdom of Lord Shiva and invites fortune. It is
        also notable that Rudra Abhishekam offered at Srisailam showers endless
        positive outcomes and the divine blessings of Sri Mallikarjuna Swamy and
        Bhramaramba.
        <br />
        <br />
        So all devotees are requested to abide by the devasthanam regulations
        and participate in Rudra abhishekam through Pratyaksha and Paroksha
        Seva's.
      </>
    ),
  },
  vedasirvachanam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p style={{ textAlign: "left" }}>
        <br></br>
        <br></br>
        The lord's true form is described in the Veda. Vedas are Apaurusheyas,
        which means 'not of a man' but penned by maharishis through divine
        calling and the blessings of the god himself. The Vedas have bestowed to
        humanity after Vakku took the form of a human saying 'Neti, Neti' since
        then recited by gurus, saints and philosophers to this day as belonging
        to none but as said by the god himself. Such Vedas to be presented to
        humans as a divine blessing in the form of Vedashirvachanam is not a
        normal feat.
        <br />
        <br />
        Isn't Vedashirvachanam a blessing from Veda Bharathi? The Vedas describe
        the true essence of divinity and the god himself.
        <br />
        <br />
        <span
          style={{
            fontWeight: "bold",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Srisailam- The Home to Vedas
        </span>
        <br />
        <br />
        The power of divinity resides at Srisailam. The divinity that can't be
        seen by earthy beings inhibits all of Srisailam. The Maharishi's who had
        the blessings and calling from god have captured the significance of
        divinity in Puranas and shastras. The devotees can now have the pleasure
        of relishing the knowledge earned and curated by Siddhis.
        <br />
        <br />
        Sri means (shiva shakti)'the power of Shiva', 'the form of Veda'. Sri
        Chakram is a sacred geometry that harbour the power of Sri. Srisailam is
        the abode to that Sri. Not only is the blessings of Shiva Shakti is upon
        Srisailam, but Srisailam itself is considered as an embodiment of Shiva
        Shakti.
        <br />
        <br></br>
        <span
          style={{
            fontWeight: "bold",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          Vedas suggest that Sri is the embodiment of Vedas. Saa Hee Sri
          Ramrutaa Sathaam- Srithi Vaakyam
        </span>
        <br />
        <br></br>
        Rugyajusaamaatmakamaina Vedavidya Sri-This perspective says that
        Srisailam is the embodiment of Vedas. This is why preachers call
        Srisailam the wealth of Vedas. Lord Shiva on the hilltops of Kashi,
        said:
        <br />
        Sheershambhupainunna Srisaila Theerthambu Mallikarjuna Swamy and Devu
        Manikipattu Vedashirvachanam which has got such significance when
        offered in the world centre, the embodier of Kailasa, Srichakra; the
        Srisailam where Sri Mallikarjuna Swamy and Bhramaramba resides, is
        beyond words. Srisailam is the supremity where divine thoughts are
        translated into holy verses. The Vedas suggest that Vedashirvachanam
        which has got such glory when offered at Srisailam grants devotees the
        blessings of Sri Mallikarjuna Swamy and Bhramaramba.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  lakshakumkumarchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        Om Sri Bhramaryai Namaha
        <br />
        <br /> Sri Bhramarambika Devi, the Shaktipeeta form at Srisaila
        Mahakshetra is offered archana from Padyami to Pournami. The Goddess on
        Pournami during the auspicious time of Pradosha, shines in her full
        glory that of a new moon. During the auspicious hour, Srisaila
        Devasthanam offers Laksha Kumkumarchana seva to Bhramarambika Devi.
        During the seva, the pundits recite the Lalita Sahasranamam 100 times.
        While the lines are recited 100 times, the goddess is worshipped using
        Kumkuma, the dearest ingredient to the goddess. It is believed that this
        ritual pleases Sri Bhramarambika Devi. Devotees who've participated in
        the Seva receive the blessings of fortune, great health, undeterred will
        and success. The women are blessed with good luck, marital fortune,
        marital happiness, blessings of offsprings and blessings of a lifetime
        from Sri Bhramarambika Devi. Devotees can participate in the Seva via
        Paroksha Seva and watch live via Srisaila Tv.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  vallidevasenasametasubramanyeswaraswamykalyanam: {
    firstbanner: [
      `Anekaarka Koti Prabhaavajjwalantam
    Manohari Maanikya Bhushojjwalantham`,
      `Srithanaamabhishtam Nishantam Nithantham
    Bhaje Shanmukham Tham Saraschandrakantham`,
    ],
    firstHeading: null,
    firstDesc: (
      <p>
        <span>
          In the glow of crore glowing suns, with all precious gems adorned, I'm
          worshipping the one who grants the wishes of devotees with a pleasant
          face as that of a moon.
          <br />
          The day Lord Shiva and Goddess Parvathi took little Subramanya Swamy
          under their wings on the day that's now celebrated as Margashira
          Shudha Shashti. Also, Skandapanchami and Kumarashashti are both of
          much importance. Subramanya Swamy has many names and here are a few:
        </span>
        <br />
        <br />
        <span style={{ textAlign: "left", display: "flex" }}>
          Shanmukudu - The one with 6 heads
          <br />
          Mahasenudu - The general of god's army
          <br />
          Kumara Swamy - The child avatar
          <br />
          Dandapani - The one who holds the guard stick
          <br />
          Valli Nayaka - The one who married Valli
          <br />
          Devasenapathi - Husband to Indra's daughter Devasena
          <br />
          Guhudu - The one with a wealth of knowledge
          <br />
          Taarakaari - The one who killed Tarakasura
          <br />
          Kaartikeyudu - The one who's mothers are Kritikas
          <br />
          Subramanyudu - The one who gifts the wisdom of Brahma
          <br />
          Agniputrudu - The one who is protected by the Fire god
          <br />
          Kritikaputrudu- The son of six Kritikas
        </span>
        <br />
        <br />
        Subramanyaswamy's birth significance is to destroy evil. He's got two
        wives, Sri Valli and Devasena. Under the Burflower tree is his favourite
        place to be. He is pleased when devotees offer him the garland of
        Burflower tree flowers. He is also pleased when devotees offer
        <br />
        Bananas, Grapes, Dates, Anjeer and Mangoes. Similarly, when cow ghee,
        honey and Kalakand are mixed into sugarcane juice and offered along with
        the saffron flower, he is most happy. Peacock is his mount and is always
        seen with a staff/spear in his hand. His mother is the goddess of all
        existence and his avatar as Subramanya Swamy is the representation of
        Kundalini. While Ganapathi considered his parents as the whole world,
        Kumaraswamy considered that the whole world is Lord Shiva.
        <br /> Srisaialam stands tall as the Maha Jyotirlingam, Shakti Peetam,
        world's centre, Home to Vedas, Kailasa on Earth and all the credit goes
        to the presence of Subramanyaswamy. Skanda Puranam and Shiva Puranam
        suggest that Srisailam is the first and foremost likeable home to
        Subramanya Swamy. At such a significant place, when Subramanyakalyanam
        is offered in the presence of Sri Mallikarjuna Swamy and Bhramaramba,
        the devotees are freed from troubles and Sarpadosha, and also are gifted
        with the wisdom of Lord Brahma. So, we urge devotees to earn the
        complete blessings of Sri Mallikarjuna swamy and Bhramaramba and
        Subramanya Swamy by availing the services of Paroksha Seva.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  kumkumarchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        The word 'Amma' comes from the two letters 'A' 'Ma', whereas in
        Sanskrit, 'A' 'Ba' form the word 'Amba'. While 'A' comes from a place of
        birth of all letters, the epiglottis and letters 'Ma' and 'Ba' come from
        the place where all letters find their destination, the lips. Hence, the
        word 'Amba' or 'Amma' serve as the origin point of all beginnings and
        endings of all other words.
        <br /> The significance of the word 'Amma' in Vedas started as
        'Maatrudevobhava'.
      </p>
    ),
    secondbanner: "Maathaa Poorvaroopam Pithotthararoopam Prajasandhihi",
    secondHeading: null,
    secondDesc: (
      <p>
        For the worldly formation, the mother is the prior form and the father
        is the latter form. The two forms come together to give rise to all of
        the living. The powerful mother and father forms come together to create
        the whole world. The mantra above says that Lord Shiva-the father,
        Shakti-the mother, The World-the result of their divine coming together.
        <br />
        The kind avatar of Lord Shiva, Aruna is protecting the devotees with
        kindness. Based on this, Shankaracharya in Soundaryalahari has laid down
        the same as, Jayathi Karuna Kaachudharuna. The Vedas also suggests that
        the body of goddess Shakti is in the color of Aruna which i.e., with the
        glory of Kukumarchana.
      </p>
    ),
    thirdBanner: (
      <>
        Arunaam Karunatarangitaakshim.... Sakumkumavilepanaam...
        <br />
        Arunimasarvasya Sampradaayena... Rakthivarnaa...
        <br />
        Tripuraam Kulanidhimidei Arunisriyam...
      </>
    ),
    thirdDesc: (
      <p>
        Brahma has taken a particle from the dust beneath the Goddess Shakti's
        foot to create the world, Lord Vishnu protects the world while Lord
        Shiva destroys it into ashes. If goddess Shakti is the avatar of
        kindness, the dust particle beneath her feat possesses kindness as well.
        Hence, the world itself is kind. Lord Shiva takes kindness in the form
        of ashes. Hence, in Lord Shiva's temple, you find ashes and at Goddess
        Shakti's temple, you find Kumkuma.
        <br />
        <br />
        Kumkuma is the most blessed and auspicious ingredient for the goddess
        Shakti. 'Kum Aithi Shabdham Karothi' Aithi Kumkuma. This says that when
        turmeric is exposed to the sun, it makes the sound, 'Kum'. Kumkuma is
        the colour of kindness(Arunavarnam). The colour of kindness(Arunavarnam)
        is the signal of kindness for the goddess Shakti. The kindness of the
        goddess is also called love. The word Kumkuma is the seed of mantras.
        So, Kumkuma is worn after worshipping the goddess. Also wearing Kumkuma
        at the centre of the forehead or mid-brow region opens up portals to
        true awakening. It also helps devotees to conquer the Rajo Gunam(mode of
        passion) and change their lives for good.
        <br />
        <br />
        Kumkumarchana when performed in Srisailam, which is considered as an
        incarnation of Kailasa, the centre of the world, and in the presence of
        Sri Mallikarjuna Bhramaramba, renders the complete positive outcomes,
        good fortune, and also showers the blessings of Sri Mallikarjuna
        Bhramarambika.
      </p>
    ),
  },
  ekanthaseva: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br></br>
        <br></br>
        One may participate in Ekantha Seva of Sri Bhramarambika Devi &
        Mallikarjuna Swamy at Srisailam through Paroksha Seva organised by
        Durga Malleswara Swamy Varla Devasthanam. This program involves Pushpotsavam and
        Nayanotsavan offered to the god and goddess. Ekantha Seva is also known
        as Pavalimpa Seva. Devotees believe those who participate in Ekantha
        Seva are cleansed of Venus malefic effects, marital difficulties, and
        are gifted with marital bliss, family progress and the birth of a baby
        boy.
        <br />
        <br /> In the main temple of Srisailam Maha Kshetram, Ekantha Seva is
        offered to Sri Bhramarambika Devi & Mallikarjuna Swamy on 9:00 PM at
        Mukha Mandapam.
        <br />
        <br /> Devotees can view the live telecast of Ekantha Seva on Srisailam
        Tv.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  rudrahomam: {
    firstbanner: [
      `Shiva Shivethi Shivethi Shivethi Vaa
    Bhava Bhavethi Bhavethi Bhavethi Vaa`,
      `Haraa Harethi Harethi Harethi Vaa
    Bhaja Manaha Shivameva Nirastharam`,
    ],
    firstHeading: null,
    firstDesc: (
      <>
        The quintessence of god is truly described in the above Veda. Ananthaa
        Vai Vedaaha, by following this Veda, Vedavyasa has divided Vedas into
        four parts from its vastness as the following: 1.Samhita 2.Aranyakam
        3.Brahmanam 4. Upanishad.
        <br />
        Among these Samhita comes first in the form of Rig, Yajus, Saama, and
        Atharva. These four are recited as four Vedas. Yajurvavedam is the heart
        of all these four Vedas. Rudranamakam has originated from this Veda
        under the names of Rudraikadashi, Sri Rudram, and Rudhraadhyayam.
        1.Although Rudraadhyayam explains the significance of god, Rudranamakam
        must be considered as a god itself while reciting it.
        <br />
        2. In Yajurveda, Rudranamakam has been pronounced as Karma Kanda, the
        same has been pronounced in Aranyakam and Brahmanam as Upaasana Kanda
        and as Gyana Kanda in Upanishad. Hence, Rudraadhyayam has been
        pronounced as Karma, Gyaana, and Upaasana Kanda in the Vedas. The Veda
        itself declared it as Shatha Rudriyam.
        <br />
        All the avatars of god have been clearly declared in Rudranamakam.
        <br />
        3.In Jabalopanishad, Gods versed in Brahma Tattva asked Sage Yagnavalkya
        'Kim Jupyena Amrutatvam Bruhi', to which the sage answered;
        Shatarudraayenethi, Eethaani Hava Amrutasya Naamaani, Ethena hava,
        Amrutattvam Bhavathi'. Which clearly states that with the above
        Rudranamakam if a Homam is performed, one would attain Amrutattvam.
        Similarly, in Skanda Puranam Suthasamhita...
      </>
    ),

    secondbanner: [
      <>
        Shatashaakaghatham Saakshaat Shatarudriyamutthamam Tasmaat
        Tajjapamaathrena Sarvapapaihi Pramuchyate <br />
        Imaani Me Rahasyaani Naamani Shrunu Chaanagha Sarva Vedeshu Geethani,
        Samsaarashamanani Cha
      </>,
    ],
    secondHeading: `From the above lines, in Vedas and all ancient holy scripts, Rudranamakam has been pronounced as the
    essence of god and to be recited during Homam and Archanas.`,
    secondDesc: (
      <>
        Sasthras describe this world as the oneness of fire and
        water(Agnisomatmakam). Agni(Light), Somamu(liquid, water). Agni is
        abundantly available in the form of light and electricity.
        Somam-Powerful, Agni- Powerful light.
        <br />
        This is Yagnatattvam. Elements of Yagna are powerful forms. These
        elements unify with the god who is an embodiment of fire. Shakti is the
        pillar for Agni and Agni is the pillar for Shakti.
        <br />
        Rudranamakam is the essence of god itself and is of such significance
        and is being recited and prayed through Rudra Homam by gods, sages,
        yogis, and gurus since ancient times at the center of the world, the Sri
        Chakra, which is Durga Malleswara Swamy Varla Devasthanam, inhibited by Sri Mallikarjuna
        Swamy and Bharamrambha. Hence it is fair to say when devotees perform
        Rudra Homam at Srisailam, it is equivalent to Archana of the god
        himself. Rudra Homam bestows redemption from sins, prevents uncalled
        troubles, and destroys all faults in your stars while showering the
        divine blessings of Sri mallikarjuna swamy and Bhramaramba
      </>
    ),
  },
  sribayaluveerabhadraswamyvarivisesharchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        Veerabhadra Swamy is the fierce formation from matted locks of Lord
        Shiva after Sathi’s self immolation at Dhaksha Yagna. Though
        mythologically he is considered as ruthless, as a prime deity of Veera
        Shaivas, he is considered as protector of devotees and bestows divine
        blessings for his faithful devotees. Devotees can see Veerabhadra Swamy
        idols at many Shiva Temples. But at Srisailam, Lord Veerabhadra is
        worshipped with special offerings and prayers. Bayalu Veerabhadra Swamy
        is mentioned as prime guard (Kshetra Palaka) of Srisaila Maha Kshetram.
        His temple is very near to main temple of Sri Bhramaramba sametha
        Mallikarjuna Swamy. Bayalu Veerabhadra Swamy’s temple has no roof and
        the lord’s idol senses the parching sun rays, heavy rainfall and cold
        breezes.
        <br />
        <br />
        Durga Malleswara Swamy Varla Devasthanam offers special rituals for Bayalu Veerabhadra Swamy
        monthly on every New Moon Day and allows devotees to participate the
        rituals from home via Paroskha Seva. Devotees believe that by offering
        seva to Sri Veerabhadra Swamy will clear off the hurdles in the way of
        success and guards their family every time. Offering special rituals
        will rid the burden of sins and Graha Dhoshas. So all devotees are
        requested to abide by the devasthanam regulations and participate in Sri
        Bayalu Veerabhadra Swamy Vari Visesha Puja at every New Moon Day
        (Amavasya) through Paroksha Seva.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  nandeeshwaraswamyvarivisesharchana: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        Vrusharaavavataram Sri Bhasaveshvaram Bhaje
        <br />
        <br />
        Once upon a time, a devotee who used to reside in a small village
        adjacent to the banks of Patalaganga wished to carve giant rock into a
        sculpture of Nandishwara and establish it in front of Mallikarjuna Swamy
        in Srisailam. Once he completed the huge sculpture, he was unsure how he
        could transport the sculpture to its rightful destination. Nandishwara
        appears in the man's dream and hands him a rope collar. He asks the man
        to tie Bengal gram to the rope and use it to transport the sculpture
        without turning back. Upon waking up, the man sees a rope collar.
        Immediately he ties soaked Bengal gram to the rope and uses it to
        transport the sculpture to Srisailam. This is how the devotee
        established the sculpture in front of Mallikarjuna Swamy. Nandishwara is
        also known as Sanagala Basavanna due to this tale. Such great Sanagala
        Basavanna is offered a special Archana every Tuesday via Paroksha Seva.
        It is believed that all who participate in the Archana are rid of mental
        hindrances and obtain mental peace. Divine knowledge, blessings as
        sacred that of teachers, great will and endurance are believed to be the
        positive outcomes. Women are rid of marital problems, evil energy and
        are blessed with happy offspring. The devotees believe that the Archana
        brings positive outcomes.
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  sriganapathipooja: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: (
      <p>
        <br />
        <br />
        On the eve of Ganesh Chaturthi, Durga Malleswara Swamy Varla Devasthanam is going to
        organize a special Pooja at one of the most significant temples of
        Srisaila Maha Kshetram, Sri Sakshi Ganapathi Temple. <br />
        Sakshi Ganapathi temple is located 2 km east of the main temple. Since
        Ganapathi presents proof that each devotee has visited Srisailam to Lord
        Shiva in Kailasa, he is called Sakshi Ganapathi. People believe that
        Devotees who offer their devotional services to Lord Ganesh will procure
        Knowledge, Discipline, Health and Wealth.
        <br />
        Most devotees who visit Srisailam, will definitely make Darshanam of
        Sakshi Ganapathi to enroll themselves as the visitors of Srisailam.{" "}
        <br />
        We all know that 'Worship from Home' is being initiated and is
        successfully organising by Durga Malleswara Swamy Varla Devasthanam. Thus, devotees who are
        not able to visit Srisailam and participate in the celebrations of
        Ganesh Chaturthi can now offer their devotion via Paroksha Seva and
        acquire the holy blessings of Sri Sakshi Ganapathi and gain the boons of
        Knowledge and Well Being. <br />
        Devasthanam offers Paroksha Seva facility for devotees to take part in
        Sri Ganapathi Pooja that takes place here on 10th Sep, 2021 at 08:30
        a.m. So all devotees are requested to abide by the devasthanam
        regulations and worship the ancient and significant Sakshi Ganapathi at
        Srisaila Maha Kshetram. <br />
      </p>
    ),
    secondbanner: null,
    secondHeading: null,
    secondDesc: null,
  },
  mahanyasapurvakarudrabhishekam: {
    firstbanner: null,
    firstHeading: null,
    firstDesc: null,
    secondbanner: (
      <>
        Nasthi Ligjarchanaath Punyamadhikam Bhuvanatraye
        <br />
        Ligjerchathe Khalu Vishwamarchitham Snannasamshayaha
        <br />
        Maayayaa Mohithaatmano Na Janatni Maheshwaram
        <br />
        Vrudivyaam Yaani Theerthani Punyaanyaayatanani Cha
        <br />
        Shivaligje Vasatnveya Taani Sarvani
      </>
    ),
    secondHeading: null,
    secondDesc: (
      <>
        {" "}
        <p>
          The supreme god manifested himself as the Lingam. All the worlds in
          the universe lie within the Lingam. This lingam holds the power of all
          three supreme gods. This is why performing Archana to the lingam is
          greater than performing Archana to the idol. Lingarchana is performing
          Archana to the whole world. All the holy places and all of the
          universe is within the Lingam. Umamaheshwara himself in Srisaila
          Khandam stated that the Mahalingam, Jyotirlangam, Mallikarjuna Swamy
          Lingam has all the significance that's mentioned above
          <br />
          Firstly, Rudhyaadhyayamu
          <br />
          1.Pradhavaanuvaakam is the prayer to Lord Rudhra
          <br />
          2.From Dvitiyaanuvaakam to Navamaanuvaakam, Lord Rudra is addressed as
          the one with the world and omnipresent mighty lord. In
          Ashtamaanuvaakam Namah Shivaya, the Shiva Panchaakshari mantram
          originates.
          <br />
          3.Dashamaanuvaakam is Lord Rudras prayer.
          <br />
          4.Ekaadasaanuvaakam is the prayer to Lord Rudra and his allies.
          <br />
          <br />
          Rudraadhyayam captures the glory of Lord Rudra and his significance.
          Tanme Manaha Shivasankalpamastu. Hence, Rudraanuvaakam recited during
          Rudrabhishekam at the centre of the world, the Sri Chakra, abode to
          the Vedas, Crown place of all mantras, which is Durga Malleswara Swamy Varla Devasthanam,
          inhibited by Sri Mallikarjuna Bhramaramba liberates devotees from all
          hurdles, renders the wisdom of Lord Shiva and invites fortune. It is
          also notable that Rudra Abhishekam offered at Srisailam showers
          endless positive outcomes and the divine blessings of Sri Mallikarjuna
          Swamy and Bhramaramba. Here at Srisaila MahaKshetram, Mahanyasam is
          performed before Rudraabhishekam on the name of Devotees. So all
          devotees are requested to abide by the devasthanam regulations and
          participate in Rudra abhishekam through Paroksha Seva.
        </p>
      </>
    ),
  },
};

const SD_Paroksha_Seva_Inner_Component_One = ({ data }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [index, setIndex] = React.useState(0);
  const [loading, setLoadin] = React.useState(true);
  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------useffect-----------------------------------------------------------//
  React.useEffect(() => {
    setLoadin(true);
    // console.log(data);
    if (data) {
      setIndex(
        data?.name
          ?.replace?.(/\s/g, "")
          ?.replace?.(/[{()}]/g, "")
          .toLowerCase()
      );
      // console.log(data.name?.replace?.(/\s/g, "").toLowerCase());
    }
    setLoadin(false);
  }, []);
  //-------------------------------------------------------------useffect-----------------------------------------------------------//
  return (
    <>
      {!loading ? (
        <div className="sd-gp-one">
          {BodyData[index]?.firstbanner !== null && (
            <div className="sd-first-banner">
              <div className="inner">
                <img
                  className="inner-image"
                  src={SD_highlets_border}
                  alt={SD_highlets_border}
                />
                <p>
                  {BodyData[index]?.firstbanner[0]}
                  <br></br>
                  <br></br>
                  {BodyData[index]?.firstbanner[1]}
                </p>
              </div>
            </div>
          )}

          <div className="t-1">
            {BodyData[index]?.firstHeading !== null && (
              <div className="top">
                <h1>
                  <span>{BodyData[index]?.firstHeading[0]}</span>
                  {BodyData[index]?.firstHeading[1]}
                </h1>
                <img src={SD_title_divider} alt={SD_title_divider}></img>
              </div>
            )}
            {BodyData[index]?.firstDesc !== null && (
              <h4 className="bottom">{BodyData[index]?.firstDesc}</h4>
            )}
          </div>
          {BodyData[index]?.secondbanner !== null && (
            <div className="sd-second-banner">
              <div className="inner">
                <img
                  className="inner-image"
                  src={SD_highlets_border}
                  alt={SD_highlets_border}
                />
                <p>{BodyData[index]?.secondbanner}</p>
              </div>
            </div>
          )}
          <div className="t-2">
            {BodyData[index]?.secondHeading !== null && (
              <div className="t-2-top">
                <h1>{BodyData[index]?.secondHeading}</h1>
                <img src={SD_title_divider} alt={SD_title_divider}></img>
              </div>
            )}
            {BodyData[index]?.secondDesc !== null && (
              <h4 className="t-2-bottom">{BodyData[index]?.secondDesc}</h4>
            )}

            {BodyData[index]?.thirdBanner && (
              <div className="sd-second-banner">
                <div className="inner">
                  <img
                    className="inner-image"
                    src={SD_highlets_border}
                    alt={SD_highlets_border}
                  />
                  <p>{BodyData[index]?.thirdBanner}</p>
                </div>
              </div>
            )}
            {BodyData[index]?.thirdDesc && (
              <h4 className="t-2-bottom">{BodyData[index]?.thirdDesc}</h4>
            )}
          </div>
          <div className="t-3">
            <img src={SD_divider} style={{ height: "20px" }} alt={SD_divider} />
            <h3>
              Sarvam Bhramaramba Sameta Mallikarjuna Charanaravindarpanamastu
            </h3>
            <img src={SD_divider} style={{ height: "20px" }} alt={SD_divider} />
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </>
  );
};
export default SD_Paroksha_Seva_Inner_Component_One;
