import React, { useContext, useEffect, useState } from "react";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withRouter } from "react-router";

const PaymentPage = ({ bookingData, history }) => {
  const { setActive, setActivePath, setPaymentPageFlag, language } =
    useContext(SD_Context);
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState(null);

  useEffect(() => {
    setActive("online-booking-payment");
    setActivePath("Onlinebooking-payment");
    handleIntialStates();
  }, []);

  const handleIntialStates = async () => {
    try {
      const paymentData = JSON.parse(localStorage.getItem("payment-context"));
      if (bookingData || paymentData) {
        setPayment(bookingData || paymentData);
        if (
          bookingData?.responseData?.paymentInformation?.ccAvenueOrder
            .merchantId
        ) {
          window.location.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${bookingData?.responseData?.paymentInformation?.ccAvenueOrder.merchantId}&encRequest=${bookingData?.responseData?.paymentInformation?.ccAvenueOrder.requestAndResponse.request}&access_code=${bookingData.responseData.priceStats.templePaymentConfig.configuration.ccavenue.accessCode}`;
        } else {
          window.location.href = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=${paymentData?.responseData?.paymentInformation?.ccAvenueOrder.merchantId}&encRequest=${paymentData?.responseData?.paymentInformation?.ccAvenueOrder.requestAndResponse.request}&access_code=${paymentData.responseData.priceStats.templePaymentConfig.configuration.ccavenue.accessCode}`;
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        toast.error("Payment Data Not found. redirecting to dashboard.!!");
        setPaymentPageFlag(false);
        setLoading(false);
        history?.push(`/${language?.code}/devotee-app/devotee-dashboard`);
        return;
      }
    } catch (error) {
      setPaymentPageFlag(false);
      toast.error(error?.message);
      localStorage.removeItem("payment-context");
      history.push(
        `${language?.code}/devotee/signin?to=devotee-app/devotee-dashboard`
      );
    }
  };

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0px",
        left: "0px",
        display: "flex",
        justifyContent: "center",
        background: "rgba(0,0,0,.5)",
        alignItems: "center",
        zIndex: "100",
      }}
    >
      {!loading ? (
        <div className="sd-profile sd-seva-form ccavenue">
          <div className="inner-payment">
            <h2
              className="sd-side-heading fw400"
              style={{ paddingBottom: "5px" }}
            >
              <span className="fw700">Payment </span>
              Page
            </h2>
            <hr />
            <h4
              style={{
                color: "red",
                fontSize: "14px",
                paddingBottom: "5px !important",
              }}
            >
              Do not close or reload this page, untill your transaction is
              complete or redirected.
            </h4>
            <div>
              Booking :{" "}
              {payment?.responseData?.data?.productDetails?.name ??
                payment?.responseData?.data?.productDetails?.productName}{" "}
              - Total : Rs.
              {payment?.responseData?.data?.price ??
                payment?.responseData?.data?.productDetails?.productActualPrice}
            </div>
            <div>
              Person Details:{" "}
              {payment?.responseData?.data?.personDetails[0]?.personName ?? ""}{" "}
              - {payment?.responseData?.data?.personDetails[0]?.gender ?? ""}
            </div>
          </div>
        </div>
      ) : (
        <Sd_loading />
      )}
    </div>
  );
};

export default withRouter(PaymentPage);
