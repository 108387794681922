import React, { useContext, useEffect, useState } from "react";
import {
  sd_alarm,
  SD_Right_Arrow_Blue,
  sd_rupee,
  // SD_ganapathi_homam_img,
  // SD_info_icon,
  sd_share,
  sd_wishlist_heart_active,
  sd_wishlist_heart_unactive,
} from "../../../../Assets/imageList";
import "../../../../Utils/sd-styles/sd-ganapathihomam.scss";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import { withRouter } from "react-router";
import { SD_Context } from "../../../../context/sd-context";
import YoutubeService from "../../../../services/sd-youtube.service";
import Iframe from "react-iframe";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const SD_Paroksha_Seva_Inner_Component_Two = ({ data, history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const { fav, addFavorite, removeFavorite } = useContext(SD_Context);
  const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  const [copyFlag, setCopyFlag] = useState(false);

  //-------------------------------------------------------------states-----------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  // useEffect(async () => {
  //   try {
  //     const videoId = await YoutubeService.getHomePageId();
  //     // console.log(videoId);
  //     setIsYoutubeLive(videoId);
  //   } catch (error) {
  //     setIsYoutubeLive(false);
  //     // console.log(`problem getting youtube video id`);
  //   }
  // }, []);
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getImage = () => {
    if (localStorage.getItem("accessToken")) {
      let res = fav?.find(
        (item) => item?.seva?.item_id === data.id && item.type === "seva"
      );
      if (res) {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_active}
            alt="active"
            onClick={() => {
              removeFavorite(res.id);
            }}
          />
        );
      } else {
        return (
          <img
            className="border-l"
            src={sd_wishlist_heart_unactive}
            alt="unactive"
            onClick={async () => {
              let resp = await addFavorite(data, "parokshaSeva");
              if (resp === "redirect") {
                history?.push(
                  `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/paroksha-seva/${data?.name
                    ?.replace?.(/\s/g, "-")
                    ?.replace(/[\\/]/g, "-")
                    .toLowerCase()}`
                );
              }
            }}
          />
        );
      }
    } else {
      return (
        <img
          className="border-l"
          src={sd_wishlist_heart_unactive}
          alt="unactive"
          onClick={async () => {
            history?.push(
              `/${selectedlanguage}/devotee/signin?to=sevas-and-darshanam/paroksha-seva/${data?.name
                ?.replace?.(/\s/g, "-")
                ?.replace(/[\\/]/g, "-")
                .toLowerCase()}`
            );
          }}
        />
      );
    }
  };
  const onShareFunction = (path) => {
    setCopyFlag(true);
    navigator.clipboard.writeText(path);
    setTimeout(() => {
      setCopyFlag(false);
    }, 2000);
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//
  return (
    <div className="sd-gp-two">
      <div className="sd-gp-seva-div">
        <img className="head-image" src={data?.image_url} alt="info" />
        <h4>{data?.name}</h4>
        <h5>{data?.description}</h5>
        <h6>
          For more information , please call us: <br></br>
          <span>18004259099 / +91 9441820717</span>
        </h6>
        <div className="sd-timings-gp">
          <h3>
            <img src={sd_alarm} alt="Rs." />
            {IndiaTime(data?.start_date).format("hh:mm A") ===
            IndiaTime(data?.end_date).format("hh:mm A") ? (
              <>
                {data.start_date &&
                  IndiaTime(data.start_date).format("hh:mm A")}
              </>
            ) : (
              <>
                {data.start_date &&
                  IndiaTime(data?.start_date).format("hh:mm A")}{" "}
                -{data?.end_date && IndiaTime(data?.end_date).format("hh:mm A")}
              </>
            )}
          </h3>
          <div>
            <img
              className="padding-r"
              src={sd_share}
              alt="share"
              onClick={() => {
                onShareFunction(
                  `https://kanakadurgamma.org/${selectedlanguage}/sevas-and-darshanam/paroksha-seva/${data?.name
                    ?.replace?.(/\s/g, "-")
                    ?.replace(/[\\/]/g, "-")
                    ?.replace?.(/[{()}]/g, "")
                    .toLowerCase()}`
                );
              }}
            />
            {getImage()}
          </div>
        </div>

        <div className="sd-booknow">
          <p>
            <img src={sd_rupee} alt="Rs." />
            {data?.price}.00
          </p>
          {!data?.disabled && (
            <button
              onClick={() => {
                if (localStorage.getItem("accessToken")) {
                  history?.push(
                    `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva/booking/${data?.name
                      ?.replace?.(/\s/g, "-")
                      ?.replace(/[\\/]/g, "-")
                      .toLowerCase()}`
                  );
                } else {
                  history?.push(
                    `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/paroksha-seva/booking/${data?.name
                      ?.replace?.(/\s/g, "-")
                      ?.replace(/[\\/]/g, "-")
                      .toLowerCase()}`
                  );
                }
              }}
            >
              Book Now{" "}
              <img
                src={SD_Right_Arrow_Blue}
                alt=""
                height="12px"
                style={{ marginLeft: "5px" }}
              />
            </button>
          )}
        </div>
      </div>
      <div className="sd-gp-video">
        <h2>
          {data?.name}
          <span>|</span> <h3>Kanipaka TV</h3>
        </h2>
        {/* {isYoutubeLive ? (
          <Iframe
            url={`https://www.youtube.com/watch?v=Kc4tToYKWsc`}
            display="initial"
          />
        ) : ( */}
        <Iframe url={`https://youtu.be/oFo_x8ubAs0`} display="initial" />
        {/* )} */}
        {/* <img src={SD_youtube_image} alt={SD_youtube_image} /> */}
      </div>{" "}
      {copyFlag && <div className="sd-copy">Link Copied</div>}{" "}
    </div>
  );
};
export default withRouter(SD_Paroksha_Seva_Inner_Component_Two);
