import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import {
  facebook,
  insta,
  mail_icon,
  nine,
  SD_appstore_icon,
  SD_Call_Icon,
  SD_email_icon,
  sd_map_icon,
  SD_playstore_icon,
  SD_Web_Icon,
  send_icon,
  youtube,
} from "../../Assets/imageList";
import NewsletterSubService from "../../services/sd-newsletter-sub.service";
import "../../Utils/sd-styles/sd-footer.scss";
import "react-toastify/dist/ReactToastify.css";
import { SD_Context } from "../../context/sd-context";
// eslint-disable-next-line react/display-name
const Footer = React.memo(({ history }) => {
  //-------------------------------------------------------------states----------------------------------------------------------//

  const [selectedLanguage, setSelectedLanguages] = React.useState("en-in");
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [errorFlag, setErrorFlag] = useState(false);
  const [subEmail, setSubEmail] = useState("");
  const [successFlag, setSuccessFlag] = useState(false);
  const { getVisitors, visitorCount } = useContext(SD_Context);
  const [errMsg, setMsg] = useState("");

  //-------------------------------------------------------------states-----------------------------------------------------------//

  //-------------------------------------------------------------functions--------------------------------------------------------//

  const subscribeButton = async () => {
    try {
      if (subEmail) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(subEmail).toLowerCase())) {
          setErrorFlag(true);
          setMsg("Please enter a valid Email address");
          return;
        }
        let res = await NewsletterSubService.subscribe(
          subEmail,
          process.env.REACT_APP_TEMPLE ?? "SDMSD"
        );
        if (res) {
          setErrorFlag(false);
          setMsg("");
        }
        // toast.success("Subscribed to newsletter.");
        setSuccessFlag(true);
        setSubEmail("");
      } else {
        setErrorFlag(true);
      }
    } catch (error) {
      setMsg(error.message);
      setErrorFlag(true);

      // toast.error(error.message);
    }
  };
  //-------------------------------------------------------------functions--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedLanguages(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedLanguages("en-in");
    }
    getVisitors();
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-footer-main">
      <div className="sd-footer-top">
        <div className="sd-footer-top-right">
          <p>Subscribe Newsletter</p>
          <div className="sd-sub">
            <div
              className={
                errorFlag
                  ? "subscribe sd-red"
                  : subEmail !== ""
                  ? " sd-orange subscribe"
                  : "subscribe"
              }
            >
              <img src={mail_icon} alt="email icon" />

              <input
                type="text"
                maxLength="30"
                placeholder="Email Address"
                style={{ textTransform: "none" }}
                onKeyUp={(e) => {
                  if (e.keyCode === 13 || e.key === "Enter") {
                    subscribeButton();
                  }
                }}
                onChange={(e) => {
                  if (errorFlag) {
                    setErrorFlag(false);
                  }
                  setSubEmail(e.target.value);
                }}
                value={subEmail}
              />
              <img
                src={send_icon}
                onClick={subscribeButton}
                alt="newsletter icon"
              />
              {errorFlag && <h4>{errMsg}</h4>}
            </div>
          </div>
        </div>
        <div className="sd-footer-top-left">
          <p>Download Mobile App</p>
          <div>
            <img
              src={SD_playstore_icon}
              alt="google play"
              style={{ marginLeft: "10px", marginRight: "10px" }}
            />
            <img src={SD_appstore_icon} alt="google play" />
          </div>
        </div>

        {successFlag && (
          <div className="callout">
            <div className="callout-header">Success</div>
            <span
              className="closebtn"
              onClick={() => {
                setSuccessFlag(false);
              }}
            >
              ×
            </span>
            <div className="callout-container">
              <p>
                Thanks for subscribing!{" "}
                {/* <a >Learn more</a> or close it if it is in your way. */}
              </p>
              <button
                onClick={() => {
                  setSuccessFlag(false);
                }}
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="sd-footer-middle">
        <footer className="text-center text-lg-start sd-footer-middle-f">
          <div
            className="sd-container-r"
            style={{
              width: "90%!important",
            }}
          >
            <div
              className="row"
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "initial",
              }}
            >
              <div className="sd-list-body w-16">
                <h5
                  className="text"
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/about`);
                  }}
                >
                  About
                </h5>
                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/about`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/the-temple/sri-kanaka-durgamma-devasthanam`
                        );
                      }}
                    >
                      The Temple
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/the-temple-history/the-history`
                        );
                      }}
                    >
                      The Temple Story
                    </a>
                  </li>

                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/about/general-information/sakshi-ganapathi`
                        );
                      }}
                    >
                      General Information
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/sevas-and-darshanam`);
                  }}
                >
                  Sevas & Darshanam{" "}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam`
                        );
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/darshanam`
                        );
                      }}
                      className="text-dark"
                    >
                      Darshanam{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/paroksha-seva`
                        );
                      }}
                      className="text-dark"
                    >
                      Paroksha Seva
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/sevas-and-darshanam/pratyaksha-seva`
                        );
                      }}
                      className="text-dark"
                    >
                      Pratyaksha Seva{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(`/`);
                      }}
                      className="text-dark"
                    >
                      Saswata Seva
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text">Donations</h5>

                <ul className="list-unstyled mb-0">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/donations`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      e-Hundi
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Devasthana Trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Annadanam trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Go Samrakshana trust
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Sri Kanaka Durga TV{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Swachh SriKanakaDurga Trust{" "}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-18">
                <h5 className="text ">Online Booking</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/online-booking`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/darshanam`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/darshanam`
                          );
                        }
                      }}
                    >
                      Darshanam Tickets
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Pratyaksha Seva Booking
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Paroksha Seva Booking
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem("accessToken")) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/donations`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/donations`
                          );
                        }
                      }}
                    >
                      Donation
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Prasadam
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        if (localStorage.getItem(`accessToken`)) {
                          history?.push(
                            `/${selectedLanguage}/devotee-app/online-booking/prasadam`
                          );
                        } else {
                          history?.push(
                            `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/prasadam-seva`
                          );
                        }
                      }}
                    >
                      Prasadam
                    </a>
                  </li> */}
                  {/* 
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Accommodation
                    </a>
                  </li> */}
                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Kalyana Katta Tickets
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/coming-soon`);
                      }}
                    >
                      Kalyana Mandapam Booking
                    </a>
                  </li> */}

                  {/* <li>
                    <a
                      className="text-dark"
                      onClick={()=>{history?.push(`/${selectedLanguage}/online-booking/srisaila-prabha`)}}
                    >
                      Srisaila Prabha
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Media Room</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/media-room`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/media-kit`
                        );
                      }}
                      className="text-dark"
                    >
                      Media Kit
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/photo-gallery`
                        );
                      }}
                      className="text-dark"
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/whats-new/temple-diary`
                        );
                      }}
                      className="text-dark"
                    >
                      Whats New
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/press/temple-news`
                        );
                      }}
                      className="text-dark"
                    >
                      Press
                    </a>
                  </li>

                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/media-room/rti-act`
                        );
                      }}
                      className="text-dark"
                    >
                      RTI Act
                    </a>
                  </li>
                </ul>
              </div>

              <div className="sd-list-body w-15">
                <h5 className="text ">Support</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support`);
                      }}
                    >
                      {" "}
                      Overview
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support/faqs-all`);
                      }}
                      className="text-dark"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/support/facilities-to-pilgrims`
                        );
                      }}
                      className="text-dark"
                    >
                      Facilities to Pilgrims
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/support/connectivity`
                        );
                      }}
                      className="text-dark"
                    >
                      Connectivity
                    </a>
                  </li>

                  {/* <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/support/call-center`)}}
                      className="text-dark"
                    >
                      Call Center
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/support/live-chat`)}}
                      className="text-dark"
                    >
                      Live Chat
                    </a>
                  </li> */}
                  <li>
                    <a
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/support/contactUs`);
                      }}
                      className="text-dark"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="sd-list-body w-15">
                <h5 className="text ">Legalities</h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/privacy-policy`);
                      }}
                    >
                      {" "}
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/refund-poclicy`);
                      }}
                    >
                      {" "}
                      Refund Policy
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/private-poclicy`);
                      }}
                    >
                      {" "}
                      Trademarks
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(
                          `/${selectedLanguage}/terms-and-conditions`
                        );
                      }}
                    >
                      {" "}
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      className="text-dark"
                      onClick={() => {
                        history?.push(`/${selectedLanguage}/private-poclicy`);
                      }}
                    >
                      {" "}
                      Cautionary Notice
                    </a>
                  </li>
                </ul>
              </div> */}

              {/* <div className="sd-list-body">
                <h5
                  className="text "
                  onClick={() => {
                    history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`);
                  }}
                >
                  Legalities{" "}
                </h5>

                <ul className="list-unstyled">
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/privacy-policy`)}}
                      className="text-dark"
                    >
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Refund Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/trademarks`)}}
                      className="text-dark"
                    >
                      Trademarks{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Terms and Conditions{" "}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={()=>{history?.push(`/${selectedLanguage}/legalities/terms-and-conditions`)}}
                      className="text-dark"
                    >
                      Cautionary Notice{" "}
                    </a>
                  </li>
                </ul>
              </div>
           */}
            </div>
          </div>

          <div className="sd-additional-footer">
            <div className="top">
              <p>
                <img src={sd_map_icon} alt="main" /> SRI DURGA MALLESWARA SWAMY
                VARLA DEVASTHANAM,
              </p>
              <p>
                <img
                  src={sd_map_icon}
                  alt="main"
                  style={{ visibility: "hidden", marginLeft: "3px" }}
                />
                10-19-54/5, BHRAMINSTREET, VIJAYAWADA, Krishna, Andhra Pradesh,
                520001
              </p>
            </div>
            <div className="bottom">
              <div className="left ">
                <p>
                  <img src={SD_Call_Icon} alt="number" /> +91 - (0866) 2423500 /
                  600, +91 9441820717
                </p>
                <p>
                  <img src={SD_email_icon} alt="mail" className="sep" />
                  endow-eosdmsd@gov.in
                </p>
                <p>
                  <img src={SD_Web_Icon} alt="mail" />
                  www.kanakadurgamma.org, aptemples.ap.gov.in
                </p>
              </div>

              <div className="right">
                <div className="left">
                  <p>
                    {" "}
                    Visitors Till Today <span>{visitorCount.total ?? 0}</span>
                  </p>{" "}
                </div>
                <div className="left">
                  <p>
                    Visitors Today <span>{visitorCount.today ?? 0}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>

      <div className="sd-footer-tag">
        <div className="left">
          <img
            onClick={() => {
              window.open(
                "https://www.facebook.com/durgamma.vijayawada",
                "_blank"
              );
            }}
            src={facebook}
            alt="icon"
          />

          <img
            onClick={() => {
              window.open(
                "https://www.youtube.com/c/Kanakadurgatemple",

                "_blank"
              );
            }}
            src={youtube}
            alt="icon"
          />
        </div>
        <div className="middle">
          <p className="border-r">
            {" "}
            Copyright © SRI DURGA MALLESWARA SWAMY VARLA DEVASTHANAMm. All
            Rights Reserved.
          </p>
          <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/privacy-policy`);
            }}
            className="border-r color"
          >
            Privacy Policy
          </p>

          <p
            onClick={() => {
              history?.push(`/${selectedLanguage}/terms-and-conditions`);
            }}
            className=" color"
          >
            Terms & Conditions
          </p>

          {/* <img
            onClick={() => {
              window.open("https://twitter.com/sbmsdevasthanam", "_blank");
            }}
            src={sd_twitter_icon_white}
            alt="icon"
          /> */}
        </div>
        <div
          className="right"
          onClick={() => {
            window.open("https://www.9and9.com/", "_blank");
          }}
        >
          Developed by{" "}
          <span>
            {" "}
            <img src={nine} alt="9and9" />
          </span>
        </div>
      </div>
    </div>
  );
});
export default withRouter(Footer);
