/* eslint-disable react/jsx-pascal-case */
import React, { useState, useContext, useEffect } from "react";
import Sd_breadcrumb from "../Components/sd-common-components/sd-breadcrumb";

import {
  sd_booking_history_icon,
  sd_devotee_dashboard,
  sd_logout_icon,
  sd_myaccount_icon,
  sd_online_booking_icon,
  sd_dashboard_phone_ico,
  sd_support,
} from "../Assets/imageList";

import { Route, Switch, withRouter } from "react-router";
import Sd_faqs_semi_view from "../Components/sd-common-components/sd-faqs-semi-view";
import Sd_dashboard_devotee_dashboard from "./sd-dashboard-inner-pages/sd-dashboard-devotee-dashboard";
import Sd_dashboard_parokshaseva_booking_confirm_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-booking-confirm-details";
import Sd_Dashboard_parokshaseva_online_booking from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-online-booking";
import Sd_dashboard_parokshaseva_seva_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-paroksha-seva/sd-dashboard-parokshaseva-booking-details";
import Sd_dashboard_ma_change_password from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-change-password";
import Sd_dashboard_ma_myprofile from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-myprofile";
import Sd_loading from "../Components/sd-common-components/sd-loading";
import Sd_dashboard_ma_devotee_master from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-devotee-master";
import Sd_dashboard_ma_favorites from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-favorites";
import Sd_dashboard_booking_history from "./sd-dashboard-inner-pages/sd-dashboard-booking-history";
import Sd_dashboard_ma_settings from "./sd-dashboard-inner-pages/sd-dashboard-my-account/sd-dashboard-ma-settings";
import Sd_dashboard_online_booking_darshanam from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard_online_booking_darshanam";
import Sd_dashboard_online_booking_pratyaksha_seva from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva";
import Sd_dashboard_online_booking_kalayanamandapam from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-kalyanamandapam";
import Sd_dashboard_online_booking_prasadam from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-prasadam";
import Sd_dashboard_online_booking_accommodation from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dasboard-accommodation/sd-dashboard-online-booking-accomadation";
import Sd_dashboard_online_booking_donations from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-donations";
import Sd_dashboard_online_booking_saswata_seva from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-saswata-seva";
import LoadingContent from "../Components/sd-common-components/sd-content-loading";
import { SD_Context } from "../context/sd-context";
import Sd_dashboard_online_donations_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-donations-confirm";
import Sd_dashboard_online_booking_darshanam_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-darshanam-confirm";
import Sd_dashboard_online_booking_accommodation_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dasboard-accommodation/sd-dashboard-online-booking-accomadation-confirm";
import Sd_dashboard_pratyakshaseva_seva_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva-edit";
import Sd_dashboard_pratyakshaseva_booking_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashbaord-online-booking-pratyaksha-seva/sd-dashboard-online-booking-pratyaksha-seva-confirm";
import Sd_dashboard_srisaila_prabha_details from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-srisaila-prabha/sd-dashboard-online-booking-srisaila-prabha-details";
import Sd_dashboard_srisaila_prabha_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-srisaila-prabha/sd-dashboard-online-booking-srisaila-prabha-confirm";
import Sd_DashboardService from "../services/sd-dashboard.service";
import Sd_dashboard_online_booking_publications_One from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-srisaila-prabha/sd-dashboard-online-booking-publications-one";
import Sd_token_fectcher from "./sd-misc/sd-admin-token-fetcher";
import { toast } from "react-toastify";
import Sd_dashboard_online_booking_publications_confirm from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/sd-dashboard-online-booking-srisaila-prabha/sd-dashboard-online-booking-publications-confirm";
import Sd_Dashboard_Donor_module from "./sd-dashboard-inner-pages/sd-donor-module/sd-dashboard-donor-module";
import "../Utils/sd-styles/sd-css/main.css";
import SuccessPage from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/payment-pages/success-page";
import FailedPage from "./sd-dashboard-inner-pages/sd-dashboard-online-booking/payment-pages/failed-page";

const SD_Dashboard = ({ history }) => {
  const {
    activePath,
    active,
    userData,
    setCurrentDashboardLower,
    devoteeList,
    setDevoteeList,
    logout,
  } = useContext(SD_Context);
  //---------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const [loading, setloading] = useState(true);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  //---------------------------------------------------------------states--------------------------------------------------------//

  //---------------------------------------------------------------consts--------------------------------------------------------//
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const templeID = process.env.REACT_APP_TEMPLE ?? "SDMSD";
  const data = [
    "my-profile",
    "change-password",
    "devotee-master-list",
    "my-favorites",
    "settings",
  ];
  const online_booking = [
    "online-booking-darshanam",
    "onlinebooking-darshanam-confirm-details",
    "online-booking-pratyakshaseva",
    "online-booking-pratyaksha-enter-details",
    "onlinebooking-pratyaksha-seva-confirm-details",
    "online-booking-parokshaseva",
    "online-booking-parokshaseva-enter-details",
    "onlinebooking-paroksha-seva-confirm-details",
    "online-booking-donations",
    "online-booking-donations-confirm",
    "publications",
    "publications-confirm",
  ];
  const myaccount = [
    "my-profile",
    "change-password",
    "devotee-master-list",
    "my-favorites",
  ];
  //---------------------------------------------------------------consts--------------------------------------------------------//

  //--------------------------------------------------------------useEffects--------------------------------------------------------//
  useEffect(async () => {
    setCurrentDashboardLower("");

    if (!localStorage.getItem("accessToken")) {
      history?.push(
        `/${selectedlanguage}/devotee/signin?to=devotee-app/online-booking/donations`
      );
    }
    setloading(true);
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    try {
      if (devoteeList === null) {
        let res = await Sd_DashboardService.get_master_list({
          page: 1,
          limit: 100,
          id: templeID,
        });
        setDevoteeList(res?.items);
      }
    } catch (e) {
      toast.warning(e.message);
      if (e.message === "Session Expired") {
        logout();

        history?.push(
          `/${selectedlanguage}/devotee/signin?to=devotee-app/devotee-dashboard`
        );
      }
    }

    setloading(false);
  }, []);

  useEffect(() => {
    if (data.includes(active)) {
      setDropTwo(true);
    }
    if (
      active === "devotee-dashboard" ||
      active === "booking-history" ||
      active === "support"
    ) {
      setDropOne(false);
      setDropTwo(false);
    }
    if (online_booking.includes(active)) {
      setDropOne(true);
    }
    if (myaccount.includes(active)) {
      setDropTwo(true);
    }
  }, [active]);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//
  return (
    <>
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="wrapper">
          <Sd_breadcrumb from={activePath} />
          <div id="sd-content">
            <section className="mt-30">
              <div className="container-fluid">
                <div className="row mlr-30">
                  <div
                    className="col-xs-12 col-sm-12 col-md-3 col-lg-3 sd-side-menu visible-md visible-lg"
                    id="side-menu"
                  >
                    <div className="sd-dashboard-profile">
                      <div className={"profile"}>
                        <p style={{ marginBottom: "0px" }}>
                          {userData?.displayName === "" ||
                          userData?.displayName === null ? (
                            "User"
                          ) : (
                            <>
                              <span>
                                {" "}
                                {userData.displayName?.split(" ")[1] ? (
                                  userData.displayName
                                    ?.split(" ")[0]
                                    ?.charAt(0)
                                    .toUpperCase()
                                ) : (
                                  <>
                                    <span>
                                      {userData.displayName
                                        ?.split(" ")[0]
                                        ?.charAt(0)
                                        .toUpperCase()}
                                      {userData.displayName
                                        ?.split(" ")[0]
                                        ?.charAt(1)}
                                    </span>
                                  </>
                                )}
                              </span>
                              <span style={{ marginLeft: "-2px" }}>
                                {userData.displayName?.split(" ")[1]?.charAt(0)}
                              </span>
                            </>
                          )}
                        </p>
                      </div>

                      {/* <img
                        className="sd-dashboard-icons"
                        src={sd_profile_icon_dashboard}
                      /> */}
                      <h2
                        className="sd-heading-18 float-right-ellipse"
                        style={{ padding: "5px !important" }}
                      >
                        {userData?.displayName}
                      </h2>
                    </div>
                    <hr className="clearfix"></hr>
                    <nav>
                      <ul className="sd-dashboard-side-menu">
                        <li
                          className={
                            active === "devotee-dashboard" ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              history?.push(
                                `/${selectedlanguage}/devotee-app/devotee-dashboard`
                              );
                            }}
                          >
                            <img
                              alt="Devotee Dashboard"
                              title="Devotee Dashboard"
                              src={sd_devotee_dashboard}
                            />
                            Dashboard
                          </a>
                        </li>
                        <li>
                          <button
                            className="collapsible"
                            onClick={() => {
                              if (dropTwo) {
                                setDropTwo(false);
                              }
                              setDropOne(!dropOne);
                            }}
                          >
                            <img
                              alt="Online Booking"
                              title="Online Booking"
                              src={sd_online_booking_icon}
                            />
                            Online Booking{" "}
                            {dropOne ? (
                              <div className="sd-plus-icon">-</div>
                            ) : (
                              <div className="sd-plus-icon">+</div>
                            )}
                          </button>

                          {dropOne ? (
                            <div className="content">
                              <ul className="sd-dashboard-side-menu-sub">
                                <li
                                  className={
                                    active === "online-booking-darshanam" ||
                                    active ===
                                      "onlinebooking-darshanam-confirm-details"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/darshanam?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Darshanam{" "}
                                  </a>
                                </li>
                                <li
                                  className={
                                    active ===
                                      "online-booking-pratyakshaseva" ||
                                    active ===
                                      "online-booking-pratyaksha-enter-details" ||
                                    active === "" ||
                                    active ===
                                      "onlinebooking-pratyaksha-seva-confirm-details"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Pratyaksha Seva{" "}
                                  </a>
                                </li>
                                <li
                                  className={
                                    active ===
                                      "online-booking-accommodations" ||
                                    active ===
                                      "online-booking-accommodation-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/accommodations?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Accommodations{" "}
                                  </a>
                                </li>
                                <li
                                  className={
                                    active === "online-booking-parokshaseva" ||
                                    active ===
                                      "online-booking-parokshaseva-enter-details" ||
                                    active ===
                                      "onlinebooking-paroksha-seva-confirm-details"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/paroksha-seva`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Paroksha Seva{" "}
                                  </a>
                                </li>
                                {/* <li
                                className={
                                  active === "online-booking-saswataseva"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/online-booking/saswata-seva`
                                    );
                                  }}
                                >
                                  {" "}
                                  Saswata Seva{" "}
                                </a>
                              </li> */}

                                <li
                                  className={
                                    active === "online-booking-donations" ||
                                    active ===
                                      "online-booking-donations-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/donations?state=clear`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Donations{" "}
                                  </a>
                                </li>
                                {/* <li
                                  className={
                                    active === "publications" ||
                                    active === "publications-confirm"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/publications`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Publications
                                  </a>
                                </li> */}

                                {/* <li
                                className={
                                  active === "online-booking-kalyanakatta"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/online-booking/kalyana-katta`
                                    );
                                  }}
                                >
                                  {" "}
                                  Kalyana Katta Tickets{" "}
                                </a>
                              </li> */}
                                {/* <li
                                className={
                                  active === "online-booking-prasadam"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/online-booking/prasadam`
                                    );
                                  }}
                                >
                                  {" "}
                                  Prasadam{" "}
                                </a>
                              </li> */}

                                {/* <li
                                className={
                                  active === "online-booking-kalayanamandapam"
                                    ? "active"
                                    : ""
                                }
                              >
                                <a
                                  
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/online-booking/kalayana-mandapam`
                                    );
                                  }}
                                >
                                  {" "}
                                  Kalyana Mandapam{" "}
                                </a>
                              </li> */}
                                {/* <li
                                  className={
                                    active === "online-booking-publications"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <a
                                    
                                    onClick={() => {
                                      history?.push(
                                        `/${selectedlanguage}/devotee-app/online-booking/publications`
                                      );
                                    }}
                                  >
                                    {" "}
                                    Publications{" "}
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>

                        <li
                          className={
                            active === "booking-history" ? "active" : ""
                          }
                        >
                          <a
                            onClick={() => {
                              history?.push(
                                `/${selectedlanguage}/devotee-app/booking-history`
                              );
                            }}
                          >
                            <img
                              alt="Booking History"
                              title="Booking History"
                              src={sd_booking_history_icon}
                            />
                            Booking History
                          </a>
                        </li>

                        <li>
                          <button
                            className="collapsible"
                            onClick={() => {
                              if (dropOne) {
                                setDropOne(false);
                              }
                              setDropTwo(!dropTwo);
                            }}
                          >
                            <img
                              alt="My Account"
                              title="My Account"
                              src={sd_myaccount_icon}
                            />
                            My Account{" "}
                            {dropTwo ? (
                              <div className="sd-plus-icon">-</div>
                            ) : (
                              <div className="sd-plus-icon">+</div>
                            )}
                          </button>

                          {dropTwo ? (
                            <div className="content">
                              <ul className="sd-dashboard-side-menu-sub">
                                <li
                                  className={
                                    active === "my-profile" ? "active" : ""
                                  }
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/my-account/profile`
                                    );
                                  }}
                                >
                                  <a> My Profile</a>
                                </li>
                                <li
                                  className={
                                    active === "change-password" ? "active" : ""
                                  }
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/my-account/change-password`
                                    );
                                  }}
                                >
                                  <a> Change Password</a>
                                </li>
                                <li
                                  className={
                                    active === "devotee-master-list"
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/my-account/devotee-masterlist`
                                    );
                                  }}
                                >
                                  <a> Devotee Master List</a>
                                </li>
                                <li
                                  className={
                                    active === "my-favorites" ? "active" : ""
                                  }
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/my-account/favorites`
                                    );
                                  }}
                                >
                                  <a> My Favorites</a>
                                </li>
                                {/* <li
                                  className={
                                    active === "settings" ? "active" : ""
                                  }
                                  onClick={() => {
                                    history?.push(
                                      `/${selectedlanguage}/devotee-app/my-account/settings`
                                    );
                                  }}
                                >
                                  <a > Settings</a>
                                </li> */}
                              </ul>
                            </div>
                          ) : (
                            ""
                          )}
                        </li>
                        <li
                          className={active === "donor_module" ? "active" : ""}
                          onClick={() => {
                            history?.push(
                              `/${selectedlanguage}/devotee-app/donor-module`
                            );
                          }}
                        >
                          <a>
                            <img
                              alt="Donor Module"
                              title="Donor Module"
                              src={sd_support}
                            />
                            Donor Module
                          </a>
                        </li>

                        <li
                          className={active === "support" ? "active" : ""}
                          onClick={() => {
                            history?.push(`/${selectedlanguage}/support`);
                          }}
                        >
                          <a>
                            <img
                              alt="Support"
                              title="Support"
                              src={sd_support}
                            />
                            Support
                          </a>
                        </li>

                        <li
                          onClick={() => {
                            logout();

                            history?.push(`/${selectedlanguage}/home`);
                          }}
                        >
                          <a>
                            <img
                              className="sd-logout-img"
                              alt="Logout"
                              title="Logout"
                              src={sd_logout_icon}
                            />
                            Logout
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div className="sd-dashboard-contact-details">
                      <p>
                        For any queries, please contact Devasthanam Information
                        Centre on
                      </p>
                      <p className="sd-phone">
                        <img src={sd_dashboard_phone_ico} />
                        <a>
                          <span>18004259099 / +91 9441820717</span>
                        </a>
                      </p>
                    </div>
                  </div>

                  <div className="route switch">
                    <Switch>
                      <Route
                        exact
                        path="/:lang/devotee-app/userview"
                        component={Sd_token_fectcher}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/donor-module"
                        component={Sd_Dashboard_Donor_module}
                      />
                      {/*------------------------------------------------------------ Devotee Dashboard-----------------------------------------------------*/}
                      <Route
                        exact
                        path="/:lang/devotee-app/devotee-dashboard"
                        component={Sd_dashboard_devotee_dashboard}
                      />
                      {/*------------------------------------------------------------ Online Booking-----------------------------------------------------*/}
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/darshanam"
                        component={Sd_dashboard_online_booking_darshanam}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/darshanam/confirm"
                        component={
                          Sd_dashboard_online_booking_darshanam_confirm
                        }
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/paroksha-seva"
                        component={Sd_Dashboard_parokshaseva_online_booking}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/paroksha-seva/confirm-details"
                        component={
                          Sd_dashboard_parokshaseva_booking_confirm_details
                        }
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/paroksha-seva/booking/:type"
                        component={Sd_dashboard_parokshaseva_seva_details}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/pratyaksha-seva"
                        component={Sd_dashboard_online_booking_pratyaksha_seva}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/pratyaksha-seva/confirm-details"
                        component={Sd_dashboard_pratyakshaseva_booking_confirm}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/pratyaksha-seva/booking/:type"
                        component={Sd_dashboard_pratyakshaseva_seva_details}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/saswata-seva"
                        component={Sd_dashboard_online_booking_saswata_seva}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/accommodations"
                        component={Sd_dashboard_online_booking_accommodation}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/accommodations/confirm"
                        component={
                          Sd_dashboard_online_booking_accommodation_confirm
                        }
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/srisaila-prabha"
                        component={Sd_dashboard_srisaila_prabha_details}
                      />{" "}
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/srisaila-prabha/confirm"
                        component={Sd_dashboard_srisaila_prabha_confirm}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/donations"
                        component={Sd_dashboard_online_booking_donations}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/donations/confirm"
                        component={Sd_dashboard_online_donations_confirm}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/prasadam"
                        component={Sd_dashboard_online_booking_prasadam}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/kalayana-mandapam"
                        component={Sd_dashboard_online_booking_kalayanamandapam}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/publications"
                        component={Sd_dashboard_online_booking_publications_One}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/publications/confirm"
                        component={
                          Sd_dashboard_online_booking_publications_confirm
                        }
                      />
                      {/*------------------------------------------------------------ Booking History-----------------------------------------------------*/}
                      <Route
                        exact
                        path="/:lang/devotee-app/booking-history"
                        component={Sd_dashboard_booking_history}
                      />
                      {/*------------------------------------------------------------ My Account-----------------------------------------------------*/}
                      <Route
                        exact
                        path="/:lang/devotee-app/my-account/change-password"
                        component={Sd_dashboard_ma_change_password}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/my-account/favorites"
                        component={Sd_dashboard_ma_favorites}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/my-account/settings"
                        component={Sd_dashboard_ma_settings}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/my-account/devotee-masterlist"
                        component={Sd_dashboard_ma_devotee_master}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/my-account/profile"
                        component={Sd_dashboard_ma_myprofile}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/payment-status"
                        component={SuccessPage}
                      />
                      <Route
                        exact
                        path="/:lang/devotee-app/online-booking/payment/failed"
                        component={FailedPage}
                      />
                      {/*------------------------------------------------------------ Support-----------------------------------------------------*/}
                      <Route
                        exact
                        path="/:lang/devotee-app/*"
                        component={LoadingContent}
                      />
                    </Switch>
                  </div>
                  {/* {displayFunction()} */}
                </div>
              </div>
            </section>
          </div>
          <br />

          <Sd_faqs_semi_view from="pratyaksha_seva" />
        </div>
      )}
    </>
  );
};
export default withRouter(SD_Dashboard);
