import Axios from "../Utils/sd-axios/Axios";

const CommsService = {
  verifyEmail: async (email) => {
    try {
      const resp = await Axios.post(
        `/mail/send-verification-mail?email=${email}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  getEmailStatus: async () => {
    try {
      const resp = await Axios.get(`mail/last-verify-log`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10,
        },
      });
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        // //console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  sendOtp: async (phone, type, token, id) => {
    try {
      const resp = await Axios.post(
        `sms/send-otp`,
        {
          phone: phone,
          type: type,
          captchaHumanKey: token,
        },
        {
          headers: {
            //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,    "X-Temple-Id": process.env.REACT_APP_TEMPLE ? 2 : 10
            "X-Temple-Code": id,
          },
        }
      );
      return resp.data;
    } catch (error) {
      if (error?.response?.status === 401) {
        throw new Error("Session Expired");
      } else if (
        error?.response?.status !== 500 &&
        error?.response?.data?.message
      ) {
        throw new Error(error?.response?.data?.message);
      } else {
        console.error(error);
        throw new Error("something is wrong");
      }
    }
  },
  // errors: async (error, errorInfo) => {
  //   try {
  //     const resp = await Axios.post(
  //       "https://ts-api.srisailadevasthanam.org/log-to-file",
  //       {
  //         data: { error, errorInfo },
  //       }
  //     );
  //     return resp.data;
  //   } catch (error) {
  //     // console.log()a
  //     // if (error?.response?.status !== 500 && error?.response?.data?.message) {
  //     //   throw new Error(error?.response?.data?.message);
  //     // } else {
  //     //   // //console.error(error);
  //     //   throw new Error("something is wrong");
  //     // }
  //   }
  // },
};

export default CommsService;
