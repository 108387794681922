import moment from "moment";
import React, { useState } from "react";
import { withRouter } from "react-router";
import {
  calender,
  hand_icon,
  SD_divider,
  sun_icon,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFour = ({ history }) => {
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");
  const [active, setActive] = useState("news");
  const timings = [
    { time: "04.00 A.M To 11.45 A.M", name: "DHARMA DARSHANAM" },
    { time: "05.00 A.M To 11.45 A.M", name: "MUKHA MANDAPA DARSHANAM" },
    { time: "05.00 A.M To 11.45 A.M", name: "SHEEGRA DARSHANAM" },
    { time: "05.00 A.M To 11.45 A.M", name: "AANTARAALAYA DARSHANAM" },
    { time: "12.15 P.M To 05.45 P.M", name: "DHARMA DARSHANAM" },
    { time: "12.15 P.M To 05.45 P.M", name: "MUKHA MANDAPA DRSHANAM" },
    { time: "12.15 P.M To 05.45 P.M", name: "SHEEGRA DARSHANAM" },
    { time: "12.15 P.M To 05.45 P.M", name: "ANTARAALAYA DARSHANAM" },
    { time: "06.30 P.M To 10.00 P.M", name: "DHARMA DARSHANAM" },
    { time: "06.30 P.M To 10.00 P.M", name: "MUKHA MANDAPA DARSHANAM" },
    { time: "06.30 P.M To 10.00 P.M", name: "SHEEGRA DARSHANAM" },
    { time: "06.30 P.M To 10.00 P.M", name: "ANTARAALAYA DARSHANAM" },
    { time: "08.45 A.M To 09.00 A.M", name: "HALT FOR BAALABHOGAM" },
    { time: "11.45 A.M To 12.15 P.M", name: "HALT FOR MAHA NIVEDANA" },
    { time: "05.45 P.M To 06.30 P.M", name: "HALT FOR PANCHAHARATULU" },
  ];

  const news = [
    // {
    //   data: "Dussera Celebrations will be started with great grandeur from 15-10-2023",
    //   month: "OCT",
    //   date: "15",
    // },
    // {
    //   data: "Sri Kanaka Durgamma Temple gearing for 10 day Dussera festivities this year",
    //   month: "SEP",
    //   date: "09",
    // },
    // {
    //   data: "Temple plans to introduce ₹500 ticket for VIPs",
    //   month: "SEP",
    //   date: "12",
    // },
    // {
    //   data: "No relinquishment of Bhavani Deeksha atop Indrakeeladri during the festival",
    //   month: "SEP",
    //   date: "13",
    // },
    // {
    //   data: "The authorities of Sri Durga Malleswara Swamy Varla Devasthanam (SDMSD) said elaborate arrangements were being made for the annual Dasara festivities, which are going to be celebrated from September 26 to October 5.",
    //   month: "SEP",
    //   date: "14",
    // },
    // {
    //   data: "Chief minister YS Jagan Mohan Reddy will visit the temple on October 2 on the occasion of Moolanakshatram and offer traditional clothes to the deity on behalf of the state government.",
    //   month: "SEP",
    //   date: "19",
    // },
  ];

  const events = [
    // {
    //   data: "Sri Bala Tripura Sundari Devi alankaram will be on 15-10-2023",
    //   month: "OCT",
    //   date: "15",
    // },
    // {
    //   data: "Sri Gayatri Devi alankaram will be on 16-10-2023",
    //   month: "OCT",
    //   date: "16",
    // },
    // {
    //   data: "Sri Annapurna Devi alankaram will be on 17-10-2023",
    //   month: "OCT",
    //   date: "17",
    // },
    // {
    //   data: "Sri Mahalakshmi Devi alankaram will be on 18-10-2023",
    //   month: "OCT",
    //   date: "18",
    // },
    // {
    //   data: "Sri Maha Chandi Devi alankaram will be on 19-10-2023",
    //   month: "OCT",
    //   date: "19",
    // },
    // {
    //   data: "Sri Saraswathi Devi From 03:00 pm to 04:00 pm Hon’ble Chief Minister of Andhra Pradesh visits the temple to offer Pattu Vasthrams.",
    //   month: "OCT",
    //   date: "20",
    // },
    // {
    //   data: "Sri Lalitha Tripura Sundari Devi alankaram will be on 21-10-2023",
    //   month: "OCT",
    //   date: "21",
    // },
    // {
    //   data: "Sri Durga Devi alankaram will be on 22-10-2023",
    //   month: "OCT",
    //   date: "22",
    // },
    // {
    //   data: "Sri Mahishasuramardhini Devi alankaram, From Noon Sri Rajarajeswari Devi alankaram will be on 23-10-2023",
    //   month: "OCT",
    //   date: "23",
    // },
  ];

  React.useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);
  return (
    <>
      <div className="top bc-yellow">
        <h1>Happenings in Temple</h1>
        <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
      </div>
      <div className="sd-template-four">
        <div className="sd-t4-one">
          <div className="sd-t4-one-start">
            <span className="span">Panchangam</span>
            <img src={sun_icon} alt="alt" />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={calender} />
          </div>
          <div
            style={{ textAlign: "center", margin: "0px" }}
            className="element-panchangam-wrapper"
          >
            <div className="element-panchangam">
              <div className="w-100">
                <p>Samvatsaram</p>
                <h5>Sri Shubhakrut Nama Samvathsaram</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Bhadrapada</p>
                <h5>Sravana</h5>
              </div>
              <div>
                <p>Paksham</p>
                <h5>Krishna</h5>
              </div>
              <div>
                <p>Sunrise</p>
                <h5>05:48 a.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Tithi</p>
                <h5>
                  Dasami upto <br /> 08:21 p.m <br /> afterwards Ekadasi
                </h5>
              </div>
              <div>
                <p>Nakshatram</p>
                <h5>
                  Punarvasu upto
                  <br /> 09:20 p.m <br />
                  afterwards Pushyami{" "}
                </h5>
              </div>
              <div>
                <p>Sunset</p>
                <h5>06:15 p.m</h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Yamagandam </p>
                <h5>
                  9:00 a.m <br /> to 10:30 a.m
                </h5>
              </div>
              <div>
                <p>Rahukalam </p>
                <h5>
                  03:00 p.m <br /> to 04:30 p.m
                </h5>
              </div>
              <div>
                <p>Amruta Gadi</p>
                <h5>
                  06:40 p.m <br />
                  to 08:26 p.m
                </h5>
              </div>
            </div>
            <div className="element-panchangam">
              <div>
                <p>Varjyam</p>
                <h5>08:01 a.m to 09:47 a.m</h5>
              </div>
              <div>
                <p>Durmuhurtham</p>
                <h5>08:18 a.m to 09:06 a.m</h5>
                <h5>10:42 p.m to 11:30 p.m</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="sd-t4-one">
          <div className="sd-t4-one-middle">
            <span className="span" style={{ color: "black" }}>
              Day Schedule
            </span>{" "}
            <img src={hand_icon} alt="alt" style={{ filter: "invert(1)" }} />
          </div>
          <div className="sd-t4-calender">
            <span>{moment().format("dddd, D MMM YYYY ")}</span>{" "}
            <img src={calender} />
          </div>

          <div className="sd-t4-one-end-m">
            {timings.map((item, i) => {
              return (
                <div key={"sasdasdsd " + i} className="element-date-m">
                  <div>{item.time}</div>
                  <p>{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="sd-t4-one">
          {" "}
          <div className="sd-t4-one-end">
            <span
              className={active === "news" ? "span-active" : ""}
              onClick={() => {
                setActive("news");
              }}
            >
              News
            </span>{" "}
            <span
              className={active === "events" ? "span-active" : ""}
              onClick={() => {
                setActive("events");
              }}
            >
              Events
            </span>{" "}
            <span
              className={active === "tenders" ? "span-active" : ""}
              onClick={() => {
                setActive("tenders");
              }}
            >
              Tenders
            </span>
          </div>
          <div className="sd-t4-one-end-d">
            {active === "news" ? (
              news?.length > 0 ? (
                news.map((item, i) => {
                  return (
                    <div key={"sasdasd +i"} className="element-date">
                      <div>
                        {item?.month}
                        <br />
                        <span>{item?.date}</span>
                      </div>
                      <p style={{ color: "#171717" }}>{item?.data}</p>
                    </div>
                  );
                })
              ) : (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No {active}
                </p>
              )
            ) : active === "events" ? (
              events?.length > 0 ? (
                events.map((item, i) => {
                  return (
                    <div key={"sasdasd +i"} className="element-date">
                      <div>
                        {item?.month}
                        <br />
                        <span>{item?.date}</span>
                      </div>
                      <p style={{ color: "#171717" }}>{item?.data}</p>
                    </div>
                  );
                })
              ) : (
                <p style={{ textAlign: "center", marginTop: "20px" }}>
                  No {active}
                </p>
              )
            ) : (
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                No {active}
              </p>
            )}
          </div>
        </div>
      </div>{" "}
    </>
  );
};
export default withRouter(TemplateFour);
