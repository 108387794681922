import React, { useContext, useEffect, useState } from "react";
import {
  sd_alarm,
  SD_Right_Arrow_white,
  sd_rupee,
  sd_script,
} from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";
import IndiaTime from "../../../../Utils/sd-moment/IndiaTime";
import PratyakshaSevaService from "../../../../services/sd-pratyakshaseva.service";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
import "../../../../Utils/sd-styles/sd-css/main.css";

const Sd_dashboard_online_booking_pratyaksha_seva = ({ history }) => {
  //-------------------------------------------------------------states-----------------------------------------------------------//

  const {
    pratyakshaSevaData,
    setPratyakshaSevaData,
    setcurrentBookingPratyaksha,
  } = useContext(SD_Context);
  const { setActive, setActivePath } = useContext(SD_Context);
  const [loading, setLoading] = useState(false);
  const [selectedlanguage, setSelectedlanguage] = useState("en-in");
  const templeID = process.env.REACT_APP_TEMPLE ?? "SDMSD";

  //-------------------------------------------------------------states---------------------------------------------------------------//

  //-------------------------------------------------------------useeffects-----------------------------------------------------------//

  useEffect(() => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    setcurrentBookingPratyaksha(null);
    if (pratyakshaSevaData.length === 0) {
      getData();
    } else {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    setActive("online-booking-pratyakshaseva");
    setActivePath("Onlinebooking-Pratyaksha Seva");
  }, []);

  //-------------------------------------------------------------useefects-----------------------------------------------------------//

  //-------------------------------------------------------------functions-----------------------------------------------------------//
  const getData = async () => {
    let data = await PratyakshaSevaService.getAllvalidOnline(templeID);
    if (data) {
      setPratyakshaSevaData(
        data.filter(
          (item) => item.sevaType === "pratyakshaSeva" && item.onlineEnabled
        )
      );
    }
    setLoading(false);
  };

  const displayFunction = () => {
    if (pratyakshaSevaData.length === 0) {
      return (
        <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          We are sorry to say that there are no pratyaksha sevas available right
          now!
        </p>
      );
    }
    let array = pratyakshaSevaData.map((item, i) => {
      if (!item?.onlineEnabled || item?.maxOnlineBookings === 0) {
        return;
      }
      return (
        <div className="seva-div-booking" key={i}>
          <img
            className="head-image-booking"
            src={item.imageUrl}
            alt={"image"}
          />
          <h4>{item?.name}</h4>
          <h5>{item.description}</h5>
          <h6
            onClick={() => {
              history?.push(
                `/${selectedlanguage}/sevas-and-darshanam/pratyaksha-seva/${item?.name
                  ?.replace?.(/\s/g, "-")
                  ?.replace(/[\\/]/g, "-")
                  ?.replace?.(/[{()}]/g, "")
                  ?.toLowerCase()}`
              );
            }}
          >
            More Info <img src={SD_Right_Arrow_white} alt=">" />
          </h6>
          {/* <div className="sd-timings-booking">
            <h3>
              <img src={sd_alarm} alt="Rs." />{" "}
              {IndiaTime(item?.start_date).format("hh:mm A") ===
              IndiaTime(item?.end_date).format("hh:mm A") ? (
                <>
                  {item?.start_date &&
                    IndiaTime(item?.start_date).format("hh:mm A")}
                </>
              ) : (
                <>
                  {item?.start_date &&
                    IndiaTime(item?.start_date).format("hh:mm A")}{" "}
                  -
                  {item?.end_date &&
                    IndiaTime(item?.end_date).format("hh:mm A")}
                </>
              )}
            </h3>
          </div> */}
          <div>
            <p>
              <img src={sd_rupee} alt="Rs." /> {item.price}
            </p>
            <button
              onClick={() => {
                history?.push(
                  `/${selectedlanguage}/devotee-app/online-booking/pratyaksha-seva/booking/${item?.name
                    ?.replace?.(/\s/g, "-")
                    ?.replace(/[\\/]/g, "-")
                    .toLowerCase()}`
                );
              }}
            >
              Book Now{" "}
              <img
                className="imgs-booking"
                src={SD_Right_Arrow_white}
                alt=">"
              />
            </button>
          </div>
        </div>
      );
    });
    return array;
  };
  //-------------------------------------------------------------functions-----------------------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {loading ? (
        <Sd_loading />
      ) : (
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-profile pr-0">
          <h2 className="col-xs-12 col-sm-12 col-md-12 col-lg-12 pl-0 xs-pr-0 sd-side-heading fw400">
            <span className="fw400">
              <span className="fw700">Pratyaksha</span> Seva
            </span>
          </h2>
          <div className="sd-seva-booking">{displayFunction()}</div>
        </div>
      )}{" "}
    </div>
  );
};

export default Sd_dashboard_online_booking_pratyaksha_seva;
