import React, { useContext, useEffect } from "react";
import { sd_failure_icon } from "../../../../Assets/imageList";
import { SD_Context } from "../../../../context/sd-context";

const FailedPage = () => {
  const { setActive, setActivePath, setcurrentBookingDarshananam } =
    useContext(SD_Context);
  const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  useEffect(() => {
    setActive("online-booking-payment-failed");
    setActivePath("Onlinebooking-payment-failed");
    let path = new URLSearchParams(history?.location?.search).get("state");

    if (path === "clear") {
      setcurrentBookingDarshananam(null);
    }
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
  }, []);

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      <div className="sd-profile sd-seva-form">
        <h2 className="sd-side-heading fw400">
          <span className="fw700">Payment </span>
          Page
        </h2>
        <div className="sd-success">
          <div className="sd-success-top sd-redback">
            <img src={sd_failure_icon} alt="success" />{" "}
            <p>
              Sorry, your booking could not be placed.
              {/* {errorMsg}  */}
            </p>
          </div>
          <div className="sd-success-bottom">
            <h3>
              Your booking was not placed as there was a problem processing
              payment. Check your booking history or SMS after 20 mins for
              confirmation. Please note that any funds debited will credit back
              to your account within 7 working days.
            </h3>
            <div className="sd-success-bottom-down">
              <p
                className="sd-border-right"
                onClick={() => {
                  // setStatus("");
                  // setSuccessOrfailureFlag(false);
                }}
              >
                Try Again
              </p>{" "}
              <p
                onClick={() => {
                  history?.push(`/${selectedlanguage}/support/contact-us`);
                }}
              >
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPage;
