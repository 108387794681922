import React, { useEffect, useState } from "react";
import {
  // ganapathi,
  // homam,
  orange_arrow,
  SD_divider,
  // seshavaham,
  template5,
} from "../../../Assets/imageList";
import "../../../Utils/sd-styles/sd-main-sup.scss";
// import YoutubeService from "../../../services/sd-youtube.service";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const TemplateFive = () => {
  //-------------------------------------------------------------states--------------------------------------------------------//
  const [selectedlanguage, setSelectedlanguage] = React.useState("en-in");

  // const [isYoutubeLive, setIsYoutubeLive] = useState(true);
  //-------------------------------------------------------------states--------------------------------------------------------//

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  useEffect(async () => {
    if (
      supportedLanguages.includes(
        history?.location?.pathname?.split?.("/")?.[1]
      )
    ) {
      setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
    } else {
      setSelectedlanguage("en-in");
    }
    // try {
    //   const videoId = await YoutubeService.getHomePageId();
    //   // console.log(videoId);
    //   setIsYoutubeLive(videoId);
    // } catch (error) {
    //   setIsYoutubeLive(false);
    //   // console.log(`problem getting youtube video id`);
    // }
  }, []);

  //-------------------------------------------------------------useEffects--------------------------------------------------------//

  return (
    <div className="sd-template-six ">
      <div className="sd-t6-one">
        <img src={template5} alt="alt" />
      </div>

      <div className="sd-t6-two">
        <div>
          <h1>About Temple</h1>
          <img src={SD_divider} alt="-" style={{ height: "20px" }}></img>
          <p>
            The temple of Kanaka Durga the Goddess of power, riches and
            benevolence and the presiding deity of Vijayawada, is thronged by
            lakhs of pilgrims for worship during the “Navarathri” festival which
            is celebrated with Religious fervor, pomp and festivity. The ancient
            temple of Kanaka Durga , a top the Indrakeeladri hill on the banks
            of the Sacred river Krishna in Vijayawada, abounds with legends of
            historical interest.
          </p>
          <h5>
            The temple of Kanaka Durga the Goddess of power, riches and
            benevolence and the presiding deity of Vijayawada, is thronged by
            lakhs of pilgrims for worship during the “Navarathri” festival which
            is celebrated with Religious fervor, pomp and festivity. The ancient
            temple of Kanaka Durga , a top the Indrakeeladri hill on the banks
            of the Sacred river Krishna in Vijayawada, abounds with legends of
            historical interest.
          </h5>
          <div className="temples">
            {" "}
            <div>
              <img src={orange_arrow} height="20px" />
              The Temple
            </div>
            <div>
              <img src={orange_arrow} height="20px" />
              The Hisory
            </div>
            <div>
              <img src={orange_arrow} height="20px" />
              General Information
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TemplateFive;
