import React, { useContext, useEffect, useRef, useState } from "react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import {
  sd_dashboard_upcoming_banner,
  SD_Dropdown_Arrow_icon,
  SD_info_icon,
  SD_newsletter_arrow_icon,
  sd_script,
  sd_side_arrow,
} from "../../../../Assets/imageList";
import Sd_loading from "../../../../Components/sd-common-components/sd-loading";
import { SD_Context } from "../../../../context/sd-context";
import Sd_DashboardService from "../../../../services/sd-dashboard.service";
import ParokshaSevaService from "../../../../services/sd-parokshaseva.service";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { BASE_BANK } from "../../../../Utils/sd-axios/sd-api-url";
const supportedLanguages = ["en-in", "jp-in", "de-in", "es-in"];

const Sd_dashboard_online_booking_publications_One = ({ history }) => {
  const {
    setActive,
    setActivePath,
    cbSrisailaPrabha,
    publicationData,
    setPublicationData,
    setCbSrisailaPrabha,
    userData,
    logout,
    devoteeList,
  } = useContext(SD_Context);

  //--------------------------------------------------states-----------------------------------------------//
  const [loading, setloading] = useState(true);
  const [selectedLanguage, setSelectedlanguage] = useState("en-in");
  const [checkbox, setCheckbox] = useState(false);
  const [shift, setShift] = useState(false);
  const [selected, setSelected] = useState("");
  const [subType, setSubType] = useState("");
  const [err, setErr] = useState([]);
  const [lang, setLang] = useState("");
  const [languageArray, setLanguageArray] = useState([]);
  const CommonRegex = /^[#.0-9a-zA-Z\s,-/]+$/;
  const [mobile, setMobile] = useState("91");
  const [email, setEmail] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [fname, setfName] = useState("");
  const [lname, setlName] = useState("");
  const [autoFill, setAutoFill] = useState("none");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("India");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [show, setShow] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [subscription, setSubscription] = useState("");
  const [subscriptionArray, setSubscriptionArray] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [yearsArray, setYearsArray] = useState([]);
  const [show1, setShow1] = useState(false);
  const [monthsArray, setMonthsArray] = useState([]);
  const [selectedMonthsArray, setSelectedMonthsArray] = useState([]);
  //--------------------------------------------------states---------------------------------------------------//
  const ref = useRef(null);
  const Months = [
    "Jan",
    "Feb",
    "March",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const styles = {
    color: "#181818",
    fontSize: "15px",
    fontWeight: "500",
    marginBottom: "8px",
  };

  const termsList = [
    `The subscription period for Saswatha Srisaila Prabha is 15 years only.`,
    `Magazine will be sent after one month from the date of remittance.`,
    `If there is any change in address, it should be intimated to the Executive Officer immediately. Please go through the Disclaimer, Privacy Policy and Copyright information in Terms & Conditions provided in website.
 `,
  ];
  //--------------------------------------------------consts---------------------------------------------------//

  //--------------------------------------------------useeffects-----------------------------------------------//

  useEffect(async () => {
    try {
      if (
        supportedLanguages.includes(
          history?.location?.pathname?.split?.("/")?.[1]
        )
      ) {
        setSelectedlanguage(history?.location?.pathname?.split?.("/")?.[1]);
      } else {
        setSelectedlanguage("en-in");
      }
      setActive("publications");
      setActivePath("Onlinebooking-Publications");
      // let result = await Sd_DashboardService.get_publications();
      // setPublicationData(result);
      // getMetaData(result);
    } catch (e) {
      if (e.message === "Session Expired") {
        setloading(false);
      } else {
        toast.error(e?.message);
        setloading(false);
      }
    }
  }, [history?.location.search]);

  useEffect(() => {
    // setYearsFunctions();
  }, [lang]);

  //--------------------------------------------------useeffects-----------------------------------------------//

  //--------------------------------------------------functions-----------------------------------------------//

  const getMetaData = async (result) => {
    const to = new URLSearchParams(history?.location?.search).get("to");
    const type = new URLSearchParams(history?.location?.search).get("type");
    if (to !== "" && to !== null && to !== undefined) {
      if (cbSrisailaPrabha) {
        setSelected(cbSrisailaPrabha?.selected);
        setSubscriptionArray(cbSrisailaPrabha?.subscriptionArray);
        setSubscription(cbSrisailaPrabha?.subscription);

        setLanguageArray(cbSrisailaPrabha?.languageArray);
        setLang(cbSrisailaPrabha?.lang);
        if (cbSrisailaPrabha?.subscription === "subscription") {
          setSubType(cbSrisailaPrabha?.subType);
        }
        if (cbSrisailaPrabha?.subscription === "download") {
          setYearsArray(cbSrisailaPrabha?.yearsArray);
          setSelectedYear(cbSrisailaPrabha?.selectedYear);
          setMonthsArray(cbSrisailaPrabha?.monthsArray);
          setSelectedMonthsArray(cbSrisailaPrabha?.selectedMonthsArray);
        }
        if (cbSrisailaPrabha?.subscription !== "download") {
          setAddress1(cbSrisailaPrabha?.address1);
          setAddress2(cbSrisailaPrabha?.address2);
          setState(cbSrisailaPrabha?.state);
          setCountry(cbSrisailaPrabha?.country);
          setCity(cbSrisailaPrabha?.city);
          setZip(cbSrisailaPrabha?.zip);
        }
        setfName(cbSrisailaPrabha?.fname);
        setlName(cbSrisailaPrabha?.lname);
        setMobile(cbSrisailaPrabha?.mobile);
        setEmail(cbSrisailaPrabha?.email);
        setShift(true);
        setloading(false);
        return;
      }
    }
    if (String(to) !== "" && to !== null && to !== undefined) {
      setShift(true);
      setSelected(result?.find((e) => e?.name === to?.replaceAll?.("-", " ")));
      dataRepFunction(
        result?.find((e) => e?.name === to?.replaceAll?.("-", " ")),
        type
      );
    } else {
      setShift(false);
      setSelected("");
      setCbSrisailaPrabha(null);
    }
    setloading(false);
  };

  const getTotalFunction = () => {
    let total = 0;
    selectedMonthsArray.map((e) => (total += e.price));
    return total;
  };

  const fnCalculateAge = (userDateinput) => {
    let birthDate = new Date(userDateinput);
    let difference = Date.now() - birthDate.getTime();
    let ageDate = new Date(difference);
    let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
    return calculatedAge;
  };

  const buttonsArrayFunction = () => {
    return selectedMonthsArray.map((item, i) => {
      return (
        <div
          className="button-div-pub"
          style={{ display: "flex", zIndex: "3" }}
          key={i}
        >
          <p
            onClick={() => {
              setShow(!show);
            }}
          >
            {item?.year}-{Months[item?.month - 1]}-Rs.{item?.price}
          </p>
          <h3
            onClick={(e) => {
              e.preventDefault();
              let data = [...selectedMonthsArray];
              setTotalCost(totalCost - item?.price);

              var filtered = data.filter(function (el) {
                return el?.id != item?.id;
              });
              setSelectedMonthsArray([...filtered]);
            }}
          >
            X
          </h3>
        </div>
      );
    });
  };

  const listFunction = () => {
    let array = monthsArray.map((item, i) => {
      let finder = null;
      for (let i = 0; i < selectedMonthsArray?.length; i++) {
        if (selectedMonthsArray[i]?.id === item?.id) {
          finder = true;
        }
      }
      return (
        <div
          key={i}
          className={finder ? "option-class sd-cyan" : "option-class"}
          onClick={() => {
            if (err.find((e) => e.type === "months" || e.type === "common")) {
              let data = err.filter((e) => {
                if (e.type !== "months" && e.type !== "common") {
                  return e;
                }
              });
              setErr(data);
            }
            let dup = selectedMonthsArray;
            let finder = selectedMonthsArray?.find((e) => e === item);
            if (finder) {
              setShow(false);
              return;
            }
            setTotalCost(totalCost + item);
            setSelectedMonthsArray([...dup, item]);
            setShow(false);
          }}
        >
          {item?.year}-{Months[item?.month - 1]}-Rs.10.00
        </div>
      );
    });
    return array;
  };

  const handleKey = (e) => {
    if (!e.target.className) {
      return;
    }
    let array =
      "option-div-pub select-div constant-top option-class sd-side-heading";

    if (
      e.target.className !== "sd-autofill-div" &&
      e.target.className !== "sd-autofill"
    ) {
      if (autoFill === "autofill") {
        setAutoFill("none");
      }
    }

    if (!array.includes(e.target.className)) {
      if (show1) {
        setTimeout(() => {
          setShow1(false);
        }, 200);
      }
      if (show) {
        setShow(false);
      }
    }
  };

  const setDataFunction = (item) => {
    let purchaseTypes = [];
    for (let i = 0; i < item.sd_publication_file.length; i++) {
      if (!purchaseTypes.find((e) => e === item.sd_publication_file[i].type)) {
        purchaseTypes.push(item.sd_publication_file[i].type);
      }
    }
    setSubscriptionArray(purchaseTypes?.length > 0 ? purchaseTypes : []);
    setSubscription(purchaseTypes[0]);
    setLanguageFunction(purchaseTypes[0], item);
    setYearsFunctions();
    setSelectedYear("");
    setMonthsArray([]);
    setSelectedMonthsArray([]);
  };

  function capitalize(str) {
    let splitStr = str?.toLowerCase?.().split?.(" ");
    for (let i = 0; i < splitStr?.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr?.join(" ");
  }

  const dataRepFunction = (item, type) => {
    let purchaseTypes = [];
    for (let i = 0; i < item.sd_publication_file.length; i++) {
      if (!purchaseTypes.find((e) => e === item.sd_publication_file[i].type)) {
        purchaseTypes.push(item.sd_publication_file[i].type);
      }
    }
    setSubscriptionArray(purchaseTypes?.length > 0 ? purchaseTypes : []);

    if (type) {
      setSubscription(type);
    } else {
      setSubscription(purchaseTypes[0]);
    }

    let SLanguages = [];
    let array = item?.sd_publication_file;
    let dupArray;
    if (type) {
      dupArray = array?.filter((e) => e.type === type);
    } else {
      dupArray = array?.filter((e) => e.type === purchaseTypes[0]);
    }
    for (let i = 0; i < dupArray?.length; i++) {
      if (dupArray[i]?.language_options?.split(",")[1]) {
        let insideArray = dupArray[i]?.language_options?.split(",");
        insideArray?.map((item) => {
          if (SLanguages.length === 0) {
            SLanguages.push(item);
          } else {
            if (!SLanguages?.find((inside) => inside === item)) {
              SLanguages.push(item);
            }
          }
        });
      } else {
        if (!SLanguages.find((e) => e === dupArray[i]?.language_options)) {
          SLanguages.push(dupArray[i]?.language_options);
        }
      }
    }
    setLanguageArray(SLanguages);
    setLang(SLanguages[0]);

    if (purchaseTypes[0] !== "subscription") {
      let years = [];
      let array = item?.sd_publication_file;
      let dupArray = [];
      if (lang === "") {
        if (type) {
          dupArray = array?.filter((e) => e.type === type);
        } else {
          dupArray = array?.filter((e) => e.type === purchaseTypes[0]);
        }
      } else {
        dupArray = array?.filter((e) => {
          if (type) {
            if (
              e?.type === type &&
              e?.language_options?.includes(SLanguages[0])
            ) {
              return e;
            }
          } else {
            if (
              e?.type === purchaseTypes[0] &&
              e?.language_options?.includes(SLanguages[0])
            ) {
              return e;
            }
          }
        });
      }
      for (let i = 0; i < dupArray?.length; i++) {
        if (!years.find((e) => e === dupArray[i]?.year)) {
          years.push(dupArray[i]?.year);
        }
      }
      setYearsArray(years);
    }

    setSelectedYear("");
    setMonthsArray([]);
    setSelectedMonthsArray([]);
  };

  const setLanguageFunction = (lang, items) => {
    let SLanguages = [];

    let array = items?.sd_publication_file;
    let dupArray = array?.filter((e) => e.type === lang);
    for (let i = 0; i < dupArray?.length; i++) {
      if (dupArray[i]?.language_options?.split(",")[1]) {
        let insideArray = dupArray[i]?.language_options?.split(",");
        insideArray?.map((item) => {
          if (SLanguages.length === 0) {
            SLanguages.push(item);
          } else {
            if (!SLanguages?.find((inside) => inside === item)) {
              SLanguages.push(item);
            }
          }
        });
      } else {
        if (!SLanguages.find((e) => e === dupArray[i]?.language_options)) {
          SLanguages.push(dupArray[i]?.language_options);
        }
      }
    }
    setLanguageArray(SLanguages);
    setYearsFunctions();
    setSelectedYear("");
    setMonthsArray([]);
    setSelectedMonthsArray([]);
    setLang(SLanguages[0]);
  };

  const setYearsFunctions = () => {
    if (subscription !== "subscription") {
      let years = [];
      let array = selected?.sd_publication_file;
      let dupArray = [];
      if (lang === "") {
        dupArray = array?.filter((e) => e.type === subscription);
      } else {
        dupArray = array?.filter((e) => {
          if (e?.type === subscription && e?.language_options?.includes(lang)) {
            return e;
          }
        });
      }
      for (let i = 0; i < dupArray?.length; i++) {
        if (!years.find((e) => e === dupArray[i]?.year)) {
          years.push(dupArray[i]?.year);
        }
      }
      setYearsArray(years);
    }
  };

  const setMonthsFunction = (year) => {
    let array = selected?.sd_publication_file;
    array = array.filter((e) => e.type === subscription);
    let months = [];
    if (lang === "") {
      array?.map((e) => e.year === year);
    } else {
      array?.map((e) => {
        if (e?.language_options?.split(",")[1]) {
          let insideArray = e?.language_options?.split(",");
          insideArray.map((item) => {
            if (item === lang && String(e.year) === year) {
              months.push(e);
            }
          });
        } else {
          if (e?.language_options === lang) {
            if (String(e.year) === year) {
              months.push(e);
            }
          }
        }
      });
    }
    setMonthsArray(months);
  };

  const handleSubmit = async () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // setloading(true);
    let a = true;
    if (a) {
      let data = [...err];
      if (checkbox === false) {
        data.push({
          type: "checkbox",
          msg: "Please accept terms and conditions",
        });
      }
      if (lang === "") {
        data.push({ type: "lang", msg: "Select a language" });
      }
      if (fname === "") {
        data.push({ type: "fname", msg: "Enter your first name" });
      } else if (fname.length < 3) {
        data.push({ type: "fname", msg: "Minimum 3 characters" });
      }
      if (lname === "") {
        data.push({ type: "lname", msg: "Enter your last name" });
      } else if (lname.length < 3) {
        data.push({ type: "lname", msg: "Minimum 3 characters" });
      }
      if (email === "") {
        data.push({ type: "email", msg: "Please enter a valid email id" });
      } else {
        if (email !== userData?.email) {
          if (!re.test(String(email).toLowerCase())) {
            data.push({ type: "email", msg: "Please enter a valid email id" });
          }
        }
      }
      if (mobile.substring(0, 2) === "91") {
        if (mobile.length !== 12) {
          data.push({ type: "mobile", msg: "Invalid mobile number" });
        }
      } else {
        if (mobile.length < 8) {
          data.push({ type: "mobile", msg: "Invalid mobile number" });
        }
      }
      if (subscription === "") {
        data.push({
          type: "subscription",
          msg: "Please select a purchase type",
        });
      }
      if (subscription !== "download") {
        if (state === "") {
          data.push({ type: "state", msg: "Please select your state" });
        }
        if (address1 === "") {
          data.push({ type: "address1", msg: "Please enter your address" });
        } else if (address1.length < 3) {
          data.push({ type: "address1", msg: "Minimum 3 characters" });
        }
        if (country === "") {
          data.push({ type: "country", msg: "Please select a country" });
        }
        if (city === "") {
          data.push({ type: "city", msg: "Please enter a city" });
        } else if (city.length < 3) {
          data.push({ type: "city", msg: "Minimum 3 characters" });
        }
        if (zip === "") {
          data.push({ type: "zip", msg: "Please enter valid zip/pinCode" });
        } else if (zip !== "" && zip !== userData?.zip) {
          // if (zip.length !== 6) {
          //   data.push({type: "zip", msg: "Please enter valid zip/pinCode"});
          // }
        }
      }
      if (subscription === "subscription") {
        if (subType === "") {
          data.push({ type: "subType", msg: "Please select a plan" });
        }
      }
      if (subscription === "download") {
        if (selectedYear === "") {
          data.push({ type: "year", msg: "Please select a year" });
        }
        if (selectedMonthsArray.length === 0) {
          data.push({ type: "months", msg: "Please select atleast one month" });
        }
      }

      if (data.length !== 0) {
        setErr(data);
        setloading(false);
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
        return;
      }
    }
    let finalSchema;
    if (subscription === "subscription") {
      let local = {
        publicationBooking: {
          full_name: `${capitalize(fname)} ${capitalize(lname)}`,
          address: capitalize(address1),
          contact: `+${mobile}`,
          pin_code: zip,
          state: state,
          email: email,
          city: capitalize(city),
          country: country,
          language: lang,
        },
        itemType: "publication",
        itemId: subType.id,
      };
      if (address2 !== "") {
        local.publicationBooking.address2 = capitalize(address2);
      }
      finalSchema = [local];
    } else if (subscription === "download") {
      let local = [];
      selectedMonthsArray.map((item) => {
        local.push({
          publicationBooking: {
            full_name: `${capitalize(fname)} ${capitalize(lname)}`,
            contact: `+${mobile}`,
            email: email,
            language: lang,
          },
          itemType: "publication",
          itemId: item.id,
        });
      });
      finalSchema = local;
    } else if (subscription === "purchase") {
      let local = {
        publicationBooking: {
          full_name: `${capitalize(fname)} ${capitalize(lname)}`,
          address: capitalize(address1),
          contact: `+${mobile}`,
          pin_code: zip,
          state: state,
          email: email,
          city: capitalize(city),
          country: country,
          language: lang,
        },
        itemType: "publication",
      };
      let selectedObj;
      selected?.sd_publication_file?.map((item) => {
        if (
          item?.type === subscription &&
          item?.language_options?.includes(String(lang))
        ) {
          selectedObj = item;
        }
      });

      local.itemId = selectedObj?.id;
      if (address2 !== "") {
        local.publicationBooking.address2 = capitalize(address2);
      }
      finalSchema = [local];
    }

    try {
      let res = await ParokshaSevaService.bookings(
        finalSchema,
        BASE_BANK,
        true
      );
      if (res) {
        let data = {
          fname,
          lname,
          mobile,
          email,
          subscription,
          lang,
          selected,
          subscriptionArray,
          languageArray,
        };
        if (subscription !== "download") {
          data.address1 = address1;
          data.address2 = address2;
          data.city = city;
          data.country = country;
          data.state = state;
          data.zip = zip;
        }
        if (subscription === "download") {
          data.monthsArray = monthsArray;
          data.yearsArray = yearsArray;
          data.selectedYear = selectedYear;
          data.selectedMonthsArray = selectedMonthsArray;
        }
        if (subscription === "subscription") {
          data.subType = subType;
        }

        setCbSrisailaPrabha(data);
        setloading(false);
        history?.push(
          `/${selectedLanguage}/devotee-app/online-booking/publications/confirm`
        );
      }
    } catch (e) {
      toast.error(e.message);
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      if (e.message === "Session Expired") {
        logout();
        history?.push(
          `/${selectedLanguage}/devotee/signin?to=devotee-app/online-booking/publications?shift=true`
        );
      }
      setloading(false);
      return;
    }
  };

  const autoFillFunction = () => {
    let array = [];

    const userCLick = () => {
      userData?.firstName
        ? setfName(userData?.firstName)
        : setfName(userData?.displayName);
      userData?.lastName ? setlName(userData?.lastName) : setlName("");

      userData?.city ? setCity(userData?.city) : setCity("");
      userData?.country && setCountry(userData?.country);
      userData?.address1 ? setAddress1(userData?.address1) : setAddress1("");
      userData?.address2 ? setAddress2(userData?.address2) : setAddress2("");
      userData?.state ? setState(userData?.state) : setState("");
      userData?.email ? setEmail(userData?.email) : setEmail("");
      userData?.phone
        ? setMobile(userData?.phone?.replace?.("+", ""))
        : setMobile("");
      userData?.pinCode ? setZip(userData?.pinCode) : setZip("");

      let data = err.filter((e) => {
        if (
          e.type !== "fname" &&
          e.type !== "lname" &&
          e.type !== "gothram" &&
          e.type !== "email" &&
          e.type !== "city" &&
          e.type !== "address1" &&
          e.type !== "state" &&
          e.type !== "country" &&
          e.type !== "phone" &&
          e.type !== "zip" &&
          e.type !== "address2" &&
          e.type !== "mobile"
        ) {
          return e;
        }
      });
      setErr(data);

      setAutoFill("none");
    };
    if (
      userData?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
      userData?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
    ) {
      array.push(
        <p
          className="sd-autofill-div"
          onClick={() => {
            userCLick();
          }}
        >
          <p
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              userCLick();
            }}
          >
            Name:{" "}
            {userData?.firstName ? userData?.firstName : userData?.displayName}
            <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
          </p>
          {userData?.dateOfBirth && (
            <p
              onClick={() => {
                userCLick();
              }}
            >
              Age:
              {fnCalculateAge(userData?.dateOfBirth)} yrs
            </p>
          )}
          {userData?.gender && (
            <p
              onClick={() => {
                userCLick();
              }}
            >
              Gender: {userData?.gender}
            </p>
          )}
        </p>
      );
    }
    if (devoteeList !== null) {
      devoteeList.forEach((items) => {
        if (
          items?.firstName?.toLowerCase()?.includes(name?.toLowerCase()) ||
          items?.lastName?.toLowerCase()?.includes(name?.toLowerCase())
        ) {
          const onClicks = () => {
            items?.firstName ? setfName(items?.firstName) : setfName("");
            items?.lastName ? setlName(items?.lastName) : setlName("");

            let data = err.filter((e) => {
              if (e.type !== "fname" && e.type !== "lname") {
                return e;
              }
            });
            setErr(data);
            setAutoFill("none");
          };
          array.push(
            <p
              key={items.id + "devlist-pub"}
              className="sd-autofill-div"
              onClick={() => {
                onClicks();
              }}
            >
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Name: {items?.firstName}
                <span style={{ marginLeft: "3px" }}>{items?.lastName}</span>
              </p>
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Age:
                {fnCalculateAge(items?.dob)} yrs
              </p>
              <p
                onClick={() => {
                  onClicks();
                }}
              >
                Gender: {items?.gender}
              </p>
            </p>
          );
        }
      });
    }

    if (array.length !== 0) {
      return (
        <div className="sd-autofill bg">
          {array.map((item) => {
            return item;
          })}
        </div>
      );
    } else {
      if (name === "") {
        let demo = (
          <p
            className="sd-autofill-div"
            onClick={() => {
              userCLick();
            }}
          >
            <p
              style={{ textTransform: "capitalize" }}
              onClick={() => {
                userCLick();
              }}
            >
              Name:{" "}
              {userData?.firstName
                ? userData?.firstName
                : userData?.displayName}
              <span style={{ marginLeft: "3px" }}>{userData?.lastName}</span>
            </p>
            {userData?.dateOfBirth && (
              <p
                onClick={() => {
                  userCLick();
                }}
              >
                Age:
                {fnCalculateAge(userData?.dateOfBirth)} yrs
              </p>
            )}
            {userData?.gender && (
              <p
                onClick={() => {
                  userCLick();
                }}
              >
                Gender: {userData?.gender}
              </p>
            )}
          </p>
        );

        let a = [...devoteeList, demo];
        return (
          <div className="sd-autofill bg">
            {a.map((item) => {
              return item;
            })}{" "}
          </div>
        );
      } else {
        return "";
      }
    }
  };

  const bookingButtons = (item) => {
    let array = [];
    let purchaseTypes = [];
    for (let i = 0; i < item.sd_publication_file.length; i++) {
      if (!purchaseTypes.find((e) => e === item.sd_publication_file[i].type)) {
        purchaseTypes.push(item.sd_publication_file[i].type);
      }
    }

    purchaseTypes.map((e) => {
      array.push(
        <button
          className="mr-10"
          onClick={() => {
            history?.push(
              `/${selectedLanguage}/devotee-app/online-booking/publications?to=${item?.name?.replace?.(
                " ",
                "-"
              )}&type=${e}`
            );
          }}
        >
          {e === "subscription"
            ? "Subscribe"
            : e === "download"
            ? "Download"
            : e === "purchase"
            ? "Book Now"
            : e}
          <img
            src={SD_newsletter_arrow_icon}
            height="10px"
            style={{ marginLeft: "5px", width: "12px" }}
          />
        </button>
      );
    });
    return array;
  };
  const languageButtons = (item) => {
    let SLanguages = [];
    let langString = "";
    let dupArray = item?.sd_publication_file;

    for (let i = 0; i < dupArray?.length; i++) {
      if (dupArray[i]?.language_options?.split(",")[1]) {
        let insideArray = dupArray[i]?.language_options?.split(",");
        insideArray?.map((item) => {
          if (SLanguages.length === 0) {
            SLanguages.push(item);
            langString += `${item} `;
          } else {
            if (!SLanguages?.find((inside) => inside === item)) {
              SLanguages.push(item);
              langString += `${item} `;
            }
          }
        });
      } else {
        if (!SLanguages.find((e) => e === dupArray[i]?.language_options)) {
          SLanguages.push(dupArray[i]?.language_options);
          langString += `${dupArray[i]?.language_options} `;
        }
      }
    }
    langString = langString.trim();
    return (
      <p style={{ textTransform: "capitalize", fontWeight: "600" }}>
        Languages: {langString?.replaceAll?.(" ", ",")}
      </p>
    );
  };

  const priceFunction = () => {
    let selectedObj;
    selected?.sd_publication_file?.map((item) => {
      if (
        item?.type === subscription &&
        item?.language_options?.includes(String(lang))
      ) {
        selectedObj = item;
      }
    });
    return (
      <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
        <label>
          Price<span className="sd-imp">*</span>
        </label>
        <input
          type="text"
          autoFocus={false}
          className={"form-control sd-voilet"}
          placeholder="Amount"
          value={selectedObj?.price}
          readOnly
        />
      </div>
    );
  };
  //--------------------------------------------------functions-----------------------------------------------//

  return (
    <div
      className="col-xs-12 col-sm-12 col-md-9 col-lg-9 sd-dashboard"
      style={{
        minHeight: document.getElementById("side-menu")?.offsetHeight,
      }}
    >
      {!loading ? (
        <div>
          <h2 className="sd-side-heading fw400" ref={ref}>
            <span className="fw700">Publications</span>
          </h2>
          {shift ? (
            <form
              className="sd-form"
              onMouseDown={(e) => {
                handleKey(e);
              }}
            >
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 mb-0 ">
                  <label ref={ref}>
                    Select Book<span className="sd-imp">*</span>
                  </label>
                  <div className="select-div relative">
                    <div
                      className={
                        selected?.name !== ""
                          ? "constant-top sd-white sd-voilet-border"
                          : "constant-top "
                      }
                      onClick={() => {
                        setShow1(!show1);
                        setShow(false);
                      }}
                    >
                      <div
                        style={{
                          width: "90%",
                          color: "#181818",
                          display: "flex",
                        }}
                      >
                        {selected?.name ? (
                          <>
                            <img
                              src={
                                selected?.cover_url
                                  ? selected?.cover_url
                                  : sd_dashboard_upcoming_banner
                              }
                              alt=""
                              height="100%"
                              width="55px"
                              className="mr-10"
                            />{" "}
                            <p className="sd-clamp">
                              {" "}
                              {selected?.name}-{selected?.description}
                            </p>
                          </>
                        ) : (
                          "Please select"
                        )}
                      </div>

                      <div
                        className="sd-input-image w-10"
                        style={{
                          position: "inherit",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          className={show1 ? "sd-image-invert" : ""}
                          style={{
                            position: "inherit",
                            justifyContent: "flex-end",
                          }}
                          src={SD_Dropdown_Arrow_icon}
                          alt=""
                          onClick={() => {
                            setShow1(!show1);
                            setShow(false);
                          }}
                        />
                      </div>
                    </div>

                    {show1 && (
                      <div
                        className="option-div-pub border-v w-85"
                        style={{ zIndex: "6", minHeight: "fit-content" }}
                      >
                        {publicationData?.map((item, i) => {
                          if (item?.disabled) {
                            return;
                          }
                          return (
                            <div
                              className="option-class "
                              style={{ alignItems: "center" }}
                              key={i + "pubdata"}
                              onClick={() => {
                                setSelected(item);
                                setTimeout(() => {
                                  setDataFunction(item);
                                  setShow1(false);
                                  setShow(false);
                                }, 100);
                              }}
                            >
                              <img
                                onClick={() => {
                                  setTimeout(() => {
                                    setDataFunction(item);
                                    setShow1(false);
                                    setShow(false);
                                  }, 100);
                                }}
                                src={
                                  item?.cover_url
                                    ? item?.cover_url
                                    : sd_dashboard_upcoming_banner
                                }
                                alt=""
                                width="50px"
                                className="mr-10"
                              />{" "}
                              {item?.name}-{item?.description}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <p className="d-EM ">
                    {err.find((e) => e.type === "type")?.msg}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>

              <div className="clearfix mb-0">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0  mb-0">
                  <label>
                    Purchase Type<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err.find((e) => e.type === "subscription")
                        ? "form-control sd-red"
                        : subscription !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={subscription}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) =>
                            e.type === "subscription" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (
                            e.type !== "subscription" &&
                            e.type !== "common"
                          ) {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setSubscription(e.target.value);
                      setLanguageFunction(e.target.value, selected);
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    {" "}
                    <option style={{ display: "none" }} value="" key="">
                      Select a purchase type
                    </option>
                    {subscriptionArray?.map((item) => {
                      return (
                        <option
                          key={item}
                          value={item}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>

                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "subscription")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0  mb-0">
                  <label>
                    Language<span className="sd-imp">*</span>
                  </label>
                  <select
                    className={
                      err.find((e) => e.type === "lang")
                        ? "form-control sd-red"
                        : lang !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    value={lang}
                    onChange={(e) => {
                      if (err.find((e) => e.type === "lang")) {
                        let data = err.filter((e) => {
                          if (e.type !== "lang") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setLang(e.target.value);
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    <option style={{ display: "none" }} value="" key="">
                      Select a language
                    </option>
                    {languageArray?.map((item) => {
                      return (
                        <option
                          key={item}
                          value={item}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>

                  {/* <p style={{position:'absolute'}}>
                  {subscription === "purchase" && `Price: ${selected}`}
                  </p> */}
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "lang")?.msg}
                  </p>
                </div>

                {subscription === "purchase" && <>{priceFunction()}</>}
              </div>
              <hr className="clearfix"></hr>

              {subscription === "download" || subscription === "pdf" ? (
                <>
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-3 col-lg-3 xssm-pr-0 pl-0 mb-0 ">
                      <label ref={ref}>
                        Select Year<span className="sd-imp">*</span>
                      </label>
                      <select
                        placeholder="Select a Book"
                        value={selectedYear}
                        defaultValue={selectedYear}
                        className={
                          err?.find((e) => e.type === `year`)
                            ? "form-control sd-red"
                            : selectedYear !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        onChange={(e) => {
                          if (err.find((e) => e.type === "year")) {
                            let data = err.filter((e) => e.type !== "year");
                            setErr(data);
                          }
                          if (e.target.value === "") {
                            return;
                          }
                          setSelectedYear(e.target.value);
                          setTimeout(() => {
                            setMonthsFunction(e.target.value);
                          }, 200);
                        }}
                      >
                        <option value="" disabled selected hidden>
                          Select a Year
                        </option>
                        {yearsArray?.map((item) => {
                          return (
                            <option
                              value={item}
                              key={item}
                              selected={item === selectedYear ? true : false}
                            >
                              {item}
                            </option>
                          );
                        })}
                      </select>
                      <p className="d-EM mr-15 ">
                        {err.find((e) => e.type === "year")?.msg}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-9 col-lg-9 xssm-pr-0  mbb-0 ">
                      <label
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          Months (You can select multiple months)
                          <span className="sd-imp">*</span>
                        </span>
                        <span style={{ float: "right" }}>
                          <img
                            src={SD_info_icon}
                            alt=""
                            height="15px"
                            className="mr-5"
                          />
                          Rs.10 for one month
                        </span>
                      </label>
                      <div className="select-div relative">
                        <div
                          className={
                            err.find((e) => e.type === "months")
                              ? "constant-top sd-white sd-red pb-0"
                              : selectedMonthsArray?.length > 0
                              ? "constant-top sd-white sd-voilet-border pb-0"
                              : "constant-top pb-0"
                          }
                          style={{ paddingBottom: "0px !important" }}
                        >
                          <div
                            className={
                              selectedMonthsArray?.length === 0
                                ? "sd-buttonArray-pub h-34"
                                : "sd-buttonArray-pub"
                            }
                            onClick={() => {
                              setShow(!show);
                            }}
                          >
                            {buttonsArrayFunction()}
                          </div>

                          <div
                            className="sd-input-image w-10"
                            onClick={() => {
                              setShow(!show);
                            }}
                          >
                            <img
                              className={show ? "sd-image-invert" : ""}
                              src={SD_Dropdown_Arrow_icon}
                              alt=""
                              onClick={() => {
                                setShow(!show);
                              }}
                            />
                          </div>
                        </div>

                        {show && (
                          <div
                            className="option-div-pub"
                            style={{ marginTop: "50px" }}
                          >
                            {listFunction()}
                          </div>
                        )}
                        <p className="sd-EM">
                          {
                            err.find(
                              (e) => e.type === "months" || e.type === "common"
                            )?.msg
                          }
                        </p>
                      </div>
                      <p className="mbb-0 mt-5">
                        Total Amount: {getTotalFunction()}
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    {" "}
                    <hr className="clearfix"></hr>
                  </div>
                </>
              ) : (
                ""
              )}
              {subscription === "subscription" && (
                <>
                  {" "}
                  <div className="clearfix ">
                    <label style={styles}>
                      Subscription plan<span className="sd-imp">*</span>{" "}
                    </label>
                    <div className="clearfix relative">
                      {selected?.sd_publication_file?.map((item, i) => {
                        if (
                          item?.language_options.includes(lang) &&
                          item?.type === "subscription"
                        )
                          return (
                            <div
                              className={
                                i % 2 === 0
                                  ? "col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 pl-0 mb-10 "
                                  : "col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 mb-10"
                              }
                              key={`annual${i}`}
                            >
                              <div
                                className={
                                  err.find(
                                    (e) =>
                                      e.type === "subType" ||
                                      e.type === "common"
                                  )
                                    ? "sd-radio-select sd-red"
                                    : subType?.id === item?.id
                                    ? "sd-radio-select sd-voilet"
                                    : "sd-radio-select  "
                                }
                                onClick={() => {
                                  if (
                                    err.find(
                                      (e) =>
                                        e.type === "subType" ||
                                        e.type === "common"
                                    )
                                  ) {
                                    let data = err.filter((e) => {
                                      if (
                                        e.type !== "subType" &&
                                        e.type !== "common"
                                      ) {
                                        return e;
                                      }
                                    });
                                    setErr(data);
                                  }
                                  setSubType(item);
                                }}
                              >
                                <input
                                  type="radio"
                                  name={`optradio1${i}`}
                                  id={`publications${i}`}
                                  checked={
                                    subType?.id === item?.id ? true : false
                                  }
                                />
                                <label
                                  htmlFor={`publications${i}`}
                                  className="radio-inline"
                                  onClick={() => {
                                    if (
                                      err.find(
                                        (e) =>
                                          e.type === "subType" ||
                                          e.type === "common"
                                      )
                                    ) {
                                      let data = err.filter((e) => {
                                        if (
                                          e.type !== "subType" &&
                                          e.type !== "common"
                                        ) {
                                          return e;
                                        }
                                      });
                                      setErr(data);
                                    }
                                    setSubType(item);
                                  }}
                                >
                                  <p>
                                    {item?.name}
                                    <br></br>
                                    <span>Rs. {item?.price}.00</span>
                                  </p>
                                </label>
                              </div>
                            </div>
                          );
                      })}
                      <p className="sd-EM mr-15" style={{ bottom: "-15px" }}>
                        {
                          err.find(
                            (e) => e.type === "subType" || e.type === "common"
                          )?.msg
                        }
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    {" "}
                    <hr className="clearfix"></hr>
                  </div>
                </>
              )}

              <h3
                className="sd-side-heading-3 fw400 mt-30"
                style={{ paddingBottom: "10px" }}
              >
                <span className="fw400">User Details</span>
              </h3>
              <hr className="clearfix"></hr>
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    First Name<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    autoFocus={false}
                    onFocus={() => {
                      if (autoFill === `autofill`) {
                        setAutoFill("none");
                      } else {
                        setAutoFill(`autofill`);
                      }
                    }}
                    autoComplete="new-password"
                    className={
                      err.find((e) => e.type === "fname")
                        ? "form-control sd-red"
                        : fname !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Please enter your full name"
                    value={fname}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (
                          err.find(
                            (e) => e.type === "fname" || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== "fname" && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setfName(e.target.value);
                      }
                    }}
                  />
                  <div>{autoFill === `autofill` && autoFillFunction()}</div>

                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "fname")?.msg}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 ">
                  <label>
                    Last Name<span className="sd-imp">*</span>
                  </label>
                  <input
                    type="text"
                    maxLength="30"
                    className={
                      err.find((e) => e.type === "lname")
                        ? "form-control sd-red"
                        : lname !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    placeholder="Please enter your full name"
                    value={lname}
                    onChange={(e) => {
                      if (
                        /^[a-zA-Z ]*$/.test(String(e.target.value)) ||
                        e.target.value === ""
                      ) {
                        if (err.find((e) => e.type === "lname")) {
                          let data = err.filter((e) => {
                            if (e.type !== "lname") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setlName(e.target.value);
                      }
                    }}
                  />

                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "lname")?.msg}
                  </p>
                </div>

                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                  <label>
                    Mobile Number<span className="sd-imp">*</span>
                  </label>

                  <PhoneInput
                    inputExtraProps={{
                      autoFocus: true,
                      enableSearch: true,
                      countryCodeEditable: false,
                    }}
                    onlyCountries={["in"]}
                    // onlyCountries={[
                    //   "in",
                    //   "au",
                    //   "ca",
                    //   "sg",
                    //   "ch",
                    //   "gb",
                    //   "us",
                    // ]}
                    placeholder="Enter Your 10 digit mobile number"
                    maxLength="15"
                    countryCodeEditable={false}
                    containerClass={
                      err?.find((e) => e.type === "mobile")
                        ? "sd-phone-voilet sd-red"
                        : mobile !== ""
                        ? "sd-phone-voilet"
                        : ""
                    }
                    country="in"
                    value={mobile}
                    onChange={(e) => {
                      if (
                        err?.find(
                          (e) => e.type === "mobile" || e.type === "common"
                        )
                      ) {
                        let array = err.filter((e) => {
                          if (e.type !== "mobile" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(array);
                      }
                      setMobile(e);
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "mobile")
                      ? err.find((e) => e.type === "mobile")?.msg
                      : ""}
                  </p>
                </div>
                <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 ">
                  <label>
                    Email address<span className="sd-imp">*</span>
                  </label>
                  <input
                    maxLength="30"
                    type="text"
                    className={
                      err.find((e) => e.type === "email")
                        ? "form-control sd-red"
                        : email !== ""
                        ? "form-control sd-voilet"
                        : "form-control"
                    }
                    style={{ textTransform: "none" }}
                    placeholder="Please enter a valid email id"
                    value={email}
                    onChange={(e) => {
                      if (
                        err.find(
                          (e) => e.type === "email" || e.type === "common"
                        )
                      ) {
                        let data = err.filter((e) => {
                          if (e.type !== "email" && e.type !== "common") {
                            return e;
                          }
                        });
                        setErr(data);
                      }
                      setEmail(e.target.value);
                    }}
                  />
                  <p className="sd-EM mr-15">
                    {err.find((e) => e.type === "email")
                      ? err.find((e) => e.type === "email")?.msg
                      : ""}
                  </p>
                </div>
              </div>
              <hr className="clearfix"></hr>
              {subscription !== "download" && (
                <>
                  {" "}
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                      <label>
                        Address Lane 1<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="50"
                        type="text"
                        placeholder="House/building number, street number"
                        className={
                          err.find((e) => e.type === "address1")
                            ? "form-control sd-red"
                            : address1 !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={address1}
                        onChange={(e) => {
                          if (
                            CommonRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) =>
                                  e.type === "address1" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "address1" &&
                                  e.type !== "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setAddress1(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM mr-15">
                        {err.find((e) => e.type === "address1")
                          ? err.find((e) => e.type === "address1")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 ">
                      <label>Address Lane 2</label>
                      <input
                        type="text"
                        maxLength="50"
                        className={
                          err.find((e) => e.type === "address2")
                            ? "form-control sd-red"
                            : address2 !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        placeholder="building name, street name, etc"
                        value={address2}
                        onChange={(e) => {
                          if (
                            CommonRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) =>
                                  e.type === "address2" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (
                                  e.type !== "address2" &&
                                  e.type !== "common"
                                ) {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setAddress2(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM mr-30">
                        {err.find((e) => e.type === "address2")
                          ? err.find((e) => e.type === "address2")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                      <label>
                        City<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="30"
                        type="text"
                        className={
                          err.find((e) => e.type === "city")
                            ? "form-control sd-red"
                            : city !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={city}
                        placeholder="Please enter your city"
                        onChange={(e) => {
                          if (
                            CommonRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === "city" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (e.type !== "city" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setCity(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM mr-15">
                        {err.find((e) => e.type === "city")
                          ? err.find((e) => e.type === "city")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0">
                      <label>
                        Zipcode<span className="sd-imp">*</span>
                      </label>
                      <input
                        type="text"
                        onWheel={(event) => event.currentTarget.blur()}
                        className={
                          err.find((e) => e.type === "zip")
                            ? "form-control sd-red"
                            : zip !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={zip}
                        maxLength={6}
                        placeholder="Enter your zipcode"
                        onChange={(e) => {
                          if (
                            CommonRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === "zip" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (e.type !== "zip" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            if (e.target.value.length < 12) {
                              setZip(e.target.value.toUpperCase());
                            }
                          }
                        }}
                      />
                      <p className="sd-EM mr-15">
                        {err.find((e) => e.type === "zip")
                          ? err.find((e) => e.type === "zip")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                      <label>
                        District<span className="sd-imp">*</span>
                      </label>
                      <input
                        maxLength="30"
                        type="text"
                        className={
                          err.find((e) => e.type === "city")
                            ? "form-control sd-red"
                            : city !== ""
                            ? "form-control sd-voilet"
                            : "form-control"
                        }
                        value={city}
                        placeholder="Please enter your District"
                        onChange={(e) => {
                          if (
                            CommonRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            if (
                              err.find(
                                (e) => e.type === "city" || e.type === "common"
                              )
                            ) {
                              let data = err.filter((e) => {
                                if (e.type !== "city" && e.type !== "common") {
                                  return e;
                                }
                              });
                              setErr(data);
                            }
                            setCity(e.target.value);
                          }
                        }}
                      />
                      <p className="sd-EM mr-15">
                        {err.find((e) => e.type === "dist")
                          ? err.find((e) => e.type === "dist")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <div className="clearfix">
                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pr-0 pl-0">
                      <label>
                        State<span className="sd-imp">*</span>
                      </label>
                      <RegionDropdown
                        country={country}
                        classes={
                          err.find((e) => e.type === "state")
                            ? "sd-red w-100 sd-border-none"
                            : state !== ""
                            ? " sd-voilet w-100 sd-border-none"
                            : "w-100 sd-border-none"
                        }
                        value={state}
                        showDefaultOption={true}
                        blankOptionLabel="Select a State"
                        onChange={(val) => {
                          if (
                            err.find(
                              (e) => e.type === "state" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "state" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setState(val);
                        }}
                      />
                      <p className="sd-EM mr-15">
                        {err.find((e) => e.type === "state")
                          ? err.find((e) => e.type === "state")?.msg
                          : ""}
                      </p>
                    </div>

                    <div className="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6  xssm-pl-0 xssm-pr-0 ">
                      <label>
                        Country<span className="sd-imp">*</span>
                      </label>
                      <CountryDropdown
                        value={country}
                        classes={
                          err.find((e) => e.type === "country")
                            ? "sd-red w-100 "
                            : country !== ""
                            ? " sd-voilet w-100 sd-border-none"
                            : "w-100 sd-border-none"
                        }
                        showDefaultOption={true}
                        defaultOptionLabel="Select a Country"
                        onChange={(val) => {
                          if (val !== "India") {
                            return;
                          }
                          if (
                            err.find(
                              (e) => e.type === "country" || e.type === "common"
                            )
                          ) {
                            let data = err.filter((e) => {
                              if (e.type !== "country" && e.type !== "common") {
                                return e;
                              }
                            });
                            setErr(data);
                          }
                          setCountry(val);
                        }}
                      />
                      <p className="sd-EM mr-30">
                        {err.find((e) => e.type === "country")
                          ? err.find((e) => e.type === "country")?.msg
                          : ""}
                      </p>
                    </div>
                  </div>
                  <hr className="clearfix"></hr>
                </>
              )}
              <div className="clearfix">
                <div className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12  xssm-pl-0 pr-0 pb-0 mb-0">
                  <ol className="sd-terms-list">
                    {subscription !== "purchase" ? (
                      <>
                        {termsList.map((item, i) => {
                          return (
                            <li className="active" key={i + "terms-pub"}>
                              <a style={{ paddingRight: "3px" }}>{item}</a>
                            </li>
                          );
                        })}
                      </>
                    ) : (
                      <li>
                        <a style={{ paddingRight: "3px" }}>
                          If there is any change in address, it should be
                          intimated to the Executive Officer immediately. Please
                          go through the Disclaimer, Privacy Policy and
                          Copyright information in Terms & Conditions provided
                          in website.
                        </a>
                      </li>
                    )}
                  </ol>
                  <div
                    className="form-group col-xs-12 col-sm-12 col-md-12 col-lg-12 xssm-pr-0 pl-0 sd-terms-checkbox"
                    style={{ alignItems: "center" }}
                  >
                    <input
                      type="checkbox"
                      name=""
                      className="mr-5"
                      value={checkbox}
                      onChange={() => {
                        if (
                          err.find(
                            (e) => e.type === `checkbox` || e.type === "common"
                          )
                        ) {
                          let data = err.filter((e) => {
                            if (e.type !== `checkbox` && e.type !== "common") {
                              return e;
                            }
                          });
                          setErr(data);
                        }
                        setCheckbox(!checkbox);
                      }}
                      checked={checkbox === true ? true : false}
                      style={{ position: "relative" }}
                    />
                    <label
                      htmlFor=""
                      style={{ marginBottom: "0px", position: "relative" }}
                    >
                      I agree to the{" "}
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/legalities/terms-and-conditions`
                          );
                        }}
                      >
                        Terms & Conditions
                      </a>{" "}
                      and the
                      <a
                        onClick={() => {
                          history?.push(
                            `/${selectedLanguage}/legalities/privacy-policy`
                          );
                        }}
                      >
                        {" "}
                        Privacy Policy
                      </a>
                    </label>
                    <p className="sd-accept ml-15 mt-5">
                      {err?.find((e) => e.type === `checkbox`)?.msg}
                    </p>
                  </div>
                </div>
              </div>
              <hr className="clearfix"></hr>
              <div className="clearfix mt-30 sd-form">
                <a
                  className="sd-btn-orange"
                  onClick={() => {
                    if (!loading) {
                      handleSubmit();
                    }
                  }}
                >
                  Continue{" "}
                  <img className="sd-arrow-btn ml-5" src={sd_side_arrow} />
                </a>
              </div>
            </form>
          ) : (
            <div className="clearfix sd-publications">
              {publicationData?.map((item, i) => {
                if (item?.disabled) {
                  return;
                }
                return (
                  <div
                    className="col-xs-12 col-sm-12 col-md-6 col-lg-6 xssm-pr-0 pl-0 min-400"
                    key={i + "pubdata-pbs"}
                  >
                    <div className="p-box">
                      <div className="sd-p-one-left">
                        <img
                          src={
                            item?.cover_url
                              ? item?.cover_url
                              : sd_dashboard_upcoming_banner
                          }
                          alt=""
                        />
                      </div>
                      <div className="sd-p-one-right">
                        <div className="sd-p-one-right-inner">
                          <h3>{item?.name}</h3>
                          <p>
                            {item?.name}-{item?.description}
                          </p>
                        </div>
                        <div>
                          {languageButtons(item)}
                          <div className="sd-dum">{bookingButtons(item)} </div>
                        </div>

                        {/* <button
                          onClick={() => {
                            history?.push(
                              `/${selectedLanguage}/devotee-app/online-booking/publications?to=${item.name?.replace?.(
                                " ",
                                "-"
                              )}`
                            );
                          }}
                        >
                          {" "}
                          Book now
                          <img
                            src={SD_newsletter_arrow_icon}
                            height="10px"
                            style={{marginLeft: "5px", width: "12px"}}
                          />
                        </button> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        // <Sd_loading />
        <p className="flex-c w-100 border-c pb-30  pt-20 br-4">
          <img
            src={sd_script}
            alt="#"
            height="80px"
            width="80px"
            className="mr-5 mb-5 opace"
          />
          We are sorry to say that online publication booking is temporarily
          unavailable. We regret the inconvenience.
        </p>
      )}
    </div>
  );
};
export default withRouter(Sd_dashboard_online_booking_publications_One);
